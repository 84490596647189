/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import {FormControl, Input, InputLabel, TextField, MenuItem, Select} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//
import {setGlobalState, useGlobalState} from "../../services/StoreServices";
import {getUrlCallOmada, handleError} from "../../services/OmadaServices";
import {getAllAction} from "../../services/ApiServices";
import ComplexStatisticsCardReport from "../../examples/Cards/StatisticsCards/ComplexStatisticsCardReport";
import DefaultDoughnutChart from "../../examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import {dateToTimeStamp, formatBytes, formatDate} from "../../services/Functions";
import GradientLineChart from "../../examples/Charts/LineCharts/GradientLineChart";
import MixedChart from "../../examples/Charts/MixedChart";

function Reports() {
  //
  const [dataTraffic, setDataTraffic] = useState(
    {
      labels: [0],
      datasets: [
        {label: "UpStream (MB)", color: "success", data: [0]},
        {label: "DownStream (MB)", color: "warning", data: [0]},
        // {label: "totalData", color: "dark", data: [0]}
      ],
    }
  );
  const [dataTrafficDistribution, setDataTrafficDistribution] = useState(
    {
      labels: [0],
      datasets: [
        {label: "wiredTraffic", color: "info", data: [0]},
        {label: "wirelessTraffic", color: "primary", data: [0]},
        // {label: "totalTraffic", color: "dark", data: [0]}
      ],
    }
  );
  const [ispLoad, setIspLoad] = useState(
    {
      labels: [0],
      datasets: [
        {chartType: "thin-bar", label: "Throughput", color: "info", data: [0]},
        {chartType: "gradient-line", label: "Latency", color: "primary", data: [0]},
      ],
    }
  );
  const [dataWiredClients, setDataWiredClients] = useState(
    {
      labels: [0],
      datasets: [
        {label: "Clientes Conectados", color: "info", data: [0]},
      ],
    }
  );
  const [dataStatistics, setDataStatistics] = useState(
    {
      labels: [0],
      datasets: [
        {label: "Pkts Enviados", color: "success", data: [0]},
        {label: "Pkts Recebidos", color: "warning", data: [0]},
      ],
    }
  );
  const [dataTopFiveApTrafficDistribution, setDataTopFiveApTrafficDistribution] = useState(
    {
      labels: [0],
      datasets: [
        {label: "ap 1", color: "dark", data: [0]},
        {label: "ap 2", color: "secondary", data: [0]},
      ],
    }
  );
  const [dataTopFiveApClientsTrafficDistribution, setDataTopFiveApClientsTrafficDistribution] = useState(
    {
      labels: [0],
      datasets: [
        {label: "ap 1", color: "dark", data: [0]},
        {label: "ap 2", color: "secondary", data: [0]},
      ],
    }
  );
  const [dataTopFiveSSIDTrafficDistribution, setDataTopFiveSSIDTrafficDistribution] = useState(
    {
      labels: [0],
      datasets: [
        {label: "ssid 1", color: "dark", data: [0]},
        {label: "ssid 2", color: "secondary", data: [0]},
      ],
    }
  );
  const [dataTopFiveSSIDClientsTrafficDistribution, setDataTopFiveSSIDClientsTrafficDistribution] = useState(
    {
      labels: [0],
      datasets: [
        {label: "ssid 1", color: "dark", data: [0]},
        {label: "ssid 2", color: "secondary", data: [0]},
      ],
    }
  );
  const [dataWirellessClientsActivities, setDataWirellessClientsActivities] = useState(
    {
      labels: [0],
      datasets: [
        {label: "ssid 1", color: "dark", data: [0]},
        {label: "ssid 2", color: "secondary", data: [0]},
      ],
    }
  );

  //
  const isLoggedInState = useGlobalState('isLoggedInState');
  const siteSelectedState = useGlobalState('siteSelectedState');
  const gatewaySiteSelectedState = useGlobalState('gatewaySiteSelectedState');
  const typeDeviceSelectedState = useGlobalState('typeDeviceSelectedState');

  //
  const dateYerterday = formatDate(new Date().setDate(new Date().getDate() - 1));
  const dateToday = formatDate(new Date());

  //
  const [report, setReport] = useState(null);
  const [dateStart, setDateStart] = useState({value: dateYerterday, timeStamp: dateToTimeStamp(`${dateYerterday} 00:00:00`)});
  const [dateEnd, setDateEnd] = useState({value: dateToday, timeStamp: dateToTimeStamp(`${dateToday} 23:59:59.999`)});
  const [timeReport, setTimeReport] = useState(0);
  const timeReportOptionsDefault = [
    {value: "0", label: "A cada 5 minutos"},
    {value: "1", label: "Por Hora"},
    {value: "2", label: "Por dia"}
  ];
  const [timeReportOptions, setTimeReportOptions] = useState(timeReportOptionsDefault);
  const [descriptionCharts, setDescriptionCharts] = useState("Informações geradas a cada 5 minutos");
  const [descriptionChartsWiredClients, setDescriptionChartsWiredClients] = useState("Informações geradas a cada 5 minutos");

  // dayjs.Dayjs
  const [minDateStart, setMinDateStart] = useState(dayjs(new Date()).subtract(30, 'days'));
  const [maxDateStart, setMaxDateStart] = useState(dayjs(new Date()));

  const [minDateEnd, setMinDateEnd] = useState(dayjs(new Date()).subtract(30, 'days'));
  const [maxDateEnd, setMaxDateEnd] = useState(dayjs(new Date()));

  /**
   *
   */
  useEffect(() => {
    console.log('Reports Mounted');
  }, []);

  /**
   *
   * @param dados
   */
  const mountIspLoad = (dados) => {
    const labels = [];
    const datasets = [];
    const totalRate = [];
    const latency = [];

    dados.data.forEach((item) => {
      labels.push(formatDate( new Date(item.time * 1000), 'Y-M-D H:i:s'));
      totalRate.push(item.totalRate);
      latency.push(item.latency);
    });

    datasets.push({chartType: "thin-bar", label: "Throughput", color: "info", data: totalRate});
    datasets.push({chartType: "gradient-line", label: "Latency", color: "primary", data: latency});

    setIspLoad({labels: labels, datasets: datasets});
  }

  const mountWiredClients = (dados) => {
    const labels = [];
    const datasets = [];
    const clientNum = [];

    dados.forEach((item) => {
      labels.push(formatDate( new Date(item.time * 1000), 'Y-M-D H:i:s'));
      clientNum.push(item.clientNum);
    });

    datasets.push({label: 'Clientes Conectados', color: 'info', data: clientNum});

    setDataWiredClients({labels: labels, datasets: datasets});
  }

  /**
   *
   * @param dados
   */
  const mountTrafficDistribution = (dados) => {
    const labels = [];
    const datasets = [];
    const totalData = [];
    const wiredData = [];
    const wirelessData = [];

    dados.trafficDistribution.forEach((item) => {
      labels.push(formatDate( new Date(item.time * 1000), 'Y-M-D H:i:s'));
      totalData.push(formatBytes(item.totalData, 2, false));
      wiredData.push(formatBytes(item.wiredData, 2, false));
      wirelessData.push(formatBytes(item.wirelessData, 2, false));
    });

    datasets.push({label: 'wiredData', color: 'info', data: wiredData});
    datasets.push({label: 'wirelessData', color: 'primary', data: wirelessData});
    // datasets.push({label: 'totalData', color: 'dark', data: totalData});

    setDataTrafficDistribution({labels: labels, datasets: datasets});
  }

  /**
   *
   * @param dados
   */
  const mountTrafficReport = (dados) => {
    const labels = [];
    const datasets = [];
    const totalData = [];
    const rxData = [];
    const txData = [];

    dados.trafficSummary.forEach((item) => {
      labels.push(formatDate( new Date(item.time * 1000), 'Y-M-D H:i:s'));
      totalData.push(formatBytes(item.totalData, 2, false));
      rxData.push(formatBytes(item.rxData, 2, false));
      txData.push(formatBytes(item.txData, 2, false));
    });

    datasets.push({
      label: "UpStream (MB)",
      color: "success",
      data: txData
    });
    datasets.push({
      label: "DownStream (MB)",
      color: "warning",
      data: rxData
    });
    // datasets.push({
    //  label: "totalData",
    //  color: "dark",
    //  data: totalData
    // });

    setDataTraffic({labels: labels, datasets: datasets});
  }

  /**
   *
   * @param dados
   */
  const mountStatistics = (dados) => {
    const labels = [];
    const datasets = [];
    const rxPktsData = [];
    const txPktsData = [];

    dados.forEach((item) => {
      if ( item.ports[0].rxPkts !== undefined || item.ports[0].txPkts !== undefined ) {
        labels.push(formatDate( new Date(item.time * 1000), 'Y-M-D H:i:s'));
        rxPktsData.push(item.ports[0].rxPkts);
        txPktsData.push(item.ports[0].txPkts);
      }
    });

    // console.log( 'mountStatistics' );
    // console.log( txPktsData );
    // console.log( rxPktsData );

    datasets.push({label: "Pkts Enviados",color: "success",data: txPktsData});
    datasets.push({label: "Pkts Recebidos",color: "warning",data: rxPktsData});

    setDataStatistics({labels: labels, datasets: datasets});
  }

  /**
   *
   */
  const mountTopFiveApTraffic = (dados) => {
    const labels = [];
    const datasets = [];
    const ap1Data = [];
    const ap2Data = [];
    const ap3Data = [];
    const ap4Data = [];
    const ap5Data = [];

    dados.timeList.forEach((item, index) => {
      labels.push(formatDate( new Date(item * 1000), 'Y-M-D H:i:s'));
      ap1Data.push(formatBytes(dados.dataList[0][index], 2, false));

      if ( dados.nameList[1] ) {
        ap2Data.push(formatBytes(dados.dataList[1][index], 2, false));
      }

      if ( dados.nameList[2] ) {
        ap3Data.push(formatBytes(dados.dataList[2][index], 2, false));
      }

      if ( dados.nameList[3] ) {
        ap4Data.push(formatBytes(dados.dataList[3][index], 2, false));
      }

      if ( dados.nameList[4] ) {
        ap5Data.push(formatBytes(dados.dataList[4][index], 2, false));
      }
    });

    datasets.push({label: dados.nameList[0], color: 'warning', data: ap1Data});

    if ( dados.nameList[1] ) {
      datasets.push({label: dados.nameList[1], color: 'success', data: ap2Data});
    }

    if ( dados.nameList[2] ) {
      datasets.push({label: dados.nameList[2], color: 'info', data: ap3Data});
    }

    if ( dados.nameList[3] ) {
      datasets.push({label: dados.nameList[3], color: 'error', data: ap4Data});
    }

    if ( dados.nameList[4] ) {
      datasets.push({label: dados.nameList[4], color: 'primary', data: ap5Data});
    }

    setDataTopFiveApTrafficDistribution({labels: labels, datasets: datasets});
  }

  const mountTopFiveApClientsTraffic = (dados) => {
    const labels = [];
    const datasets = [];
    const ap1Data = [];
    const ap2Data = [];
    const ap3Data = [];
    const ap4Data = [];
    const ap5Data = [];

    dados.timeList.forEach((item, index) => {
      labels.push(formatDate( new Date(item * 1000), 'Y-M-D H:i:s'));
      ap1Data.push(dados.dataList[0][index]);

      if ( dados.nameList[1] ) {
        ap2Data.push(dados.dataList[1][index]);
      }

      if ( dados.nameList[2] ) {
        ap3Data.push(dados.dataList[2][index]);
      }

      if ( dados.nameList[3] ) {
        ap4Data.push(dados.dataList[3][index]);
      }

      if ( dados.nameList[4] ) {
        ap5Data.push(dados.dataList[4][index]);
      }
    });

    datasets.push({label: dados.nameList[0], color: 'warning', data: ap1Data});

    if ( dados.nameList[1] ) {
      datasets.push({label: dados.nameList[1], color: 'success', data: ap2Data});
    }

    if ( dados.nameList[2] ) {
      datasets.push({label: dados.nameList[2], color: 'info', data: ap3Data});
    }

    if ( dados.nameList[3] ) {
      datasets.push({label: dados.nameList[3], color: 'error', data: ap4Data});
    }

    if ( dados.nameList[4] ) {
      datasets.push({label: dados.nameList[4], color: 'primary', data: ap5Data});
    }

    setDataTopFiveApClientsTrafficDistribution({labels: labels, datasets: datasets});
  }

  const getApSummaryNames = (dados) => {
    const names = [];

    dados.forEach((item) => {
      names.push(item.name);
    });

    return names;
  }

  const getApSummaryData = (dados) => {
    const txData = [];

    dados.forEach((item) => {
      txData.push(formatBytes(item.traffic, 2, false));
    });

    return txData;
  }

  const getBandSummaryNames = (dados) => {
    // const names = [];
    const names = ['2.4 GHz', '5 GHz', '6 GHz'];

    return names;
  }

  const getBandSummaryData = (dados) => {
    const txData = [];

    txData.push(formatBytes(dados.total2gTraffic, 2, false));
    txData.push(formatBytes(dados.total5gTraffic, 2, false));
    txData.push(formatBytes(dados.total6gTraffic, 2, false));

    return txData;
  }

  const mountTopFiveSSIDTraffic = (dados) => {
    const labels = [];
    const datasets = [];
    const ssid1Data = [];
    const ssid2Data = [];
    const ssid3Data = [];
    const ssid4Data = [];
    const ssid5Data = [];

    dados.timeList.forEach((item, index) => {
      labels.push(formatDate( new Date(item * 1000), 'Y-M-D H:i:s'));
      ssid1Data.push(formatBytes(dados.dataList[0][index], 2, false));

      if ( dados.nameList[1] ) {
        ssid2Data.push(formatBytes(dados.dataList[1][index], 2, false));
      }

      if ( dados.nameList[2] ) {
        ssid3Data.push(formatBytes(dados.dataList[2][index], 2, false));
      }

      if ( dados.nameList[3] ) {
        ssid4Data.push(formatBytes(dados.dataList[3][index], 2, false));
      }

      if ( dados.nameList[4] ) {
        ssid5Data.push(formatBytes(dados.dataList[4][index], 2, false));
      }
    });

    datasets.push({label: dados.nameList[0], color: 'primary', data: ssid1Data});

    if ( dados.nameList[1] ) {
      datasets.push({label: dados.nameList[1], color: 'secondary', data: ssid2Data});
    }

    if ( dados.nameList[2] ) {
      datasets.push({label: dados.nameList[2], color: 'info', data: ssid3Data});
    }

    if ( dados.nameList[3] ) {
      datasets.push({label: dados.nameList[3], color: 'success', data: ssid4Data});
    }

    if ( dados.nameList[4] ) {
      datasets.push({label: dados.nameList[4], color: 'warning', data: ssid5Data});
    }

    setDataTopFiveSSIDTrafficDistribution({labels: labels, datasets: datasets});
  }

  const mountTopFiveSSIDClientsTrafficDistribution = (dados) => {
    const labels = [];
    const datasets = [];
    const ssid1Data = [];
    const ssid2Data = [];
    const ssid3Data = [];
    const ssid4Data = [];
    const ssid5Data = [];

    dados.timeList.forEach((item, index) => {
      labels.push(formatDate( new Date(item * 1000), 'Y-M-D H:i:s'));
      ssid1Data.push(dados.dataList[0][index]);

      if ( dados.nameList[1] ) {
        ssid2Data.push(dados.dataList[1][index]);
      }

      if ( dados.nameList[2] ) {
        ssid3Data.push(dados.dataList[2][index]);
      }

      if ( dados.nameList[3] ) {
        ssid4Data.push(dados.dataList[3][index]);
      }

      if ( dados.nameList[4] ) {
        ssid5Data.push(dados.dataList[4][index]);
      }
    });

    datasets.push({label: dados.nameList[0], color: 'primary', data: ssid1Data});

    if ( dados.nameList[1] ) {
      datasets.push({label: dados.nameList[1], color: 'secondary', data: ssid2Data});
    }

    if ( dados.nameList[2] ) {
      datasets.push({label: dados.nameList[2], color: 'info', data: ssid3Data});
    }

    if ( dados.nameList[3] ) {
      datasets.push({label: dados.nameList[3], color: 'success', data: ssid4Data});
    }

    if ( dados.nameList[4] ) {
      datasets.push({label: dados.nameList[4], color: 'warning', data: ssid5Data});
    }

    setDataTopFiveSSIDClientsTrafficDistribution({labels: labels, datasets: datasets});
  }

  const mountWirellesClientsActivities = (dados) => {
    const labels = [];
    const datasets = [];
    const band2gData = [];
    const band5gData = [];
    const band6gData = [];

    dados.wirelessClientsActivitiesList.forEach((item, index) => {
      labels.push(formatDate( new Date(item.time * 1000), 'Y-M-D H:i:s'));
      band2gData.push(item.wireless2gClients);
      band5gData.push(item.wireless5gClients);
      band6gData.push(item.wireless6gClients);
    });

    datasets.push({label: '2.4 GHz', color: 'primary', data: band2gData});
    datasets.push({label: '5 GHz', color: 'secondary', data: band5gData});
    datasets.push({label: '6 GHz', color: 'info', data: band6gData});

    setDataWirellessClientsActivities({labels: labels, datasets: datasets});
  }

  /**
   * Get Network Report
   * @returns {Promise<void>}
   */
  const getNetworkReport = async () => {
    console.log( 'getNetworkReport' );
    setGlobalState('isLoadingState', true);

    let url = getUrlCallOmada();
    url = url !== '' ? `${url}&site_id=${siteSelectedState[0]}` : '';
    url = url !== '' ? `${url}&time_report=${timeReport}&date_start=${dateStart.timeStamp}&date_end=${dateEnd.timeStamp}` : '';

    console.log( 'typeDeviceSelectedState[0]' );
    console.log( typeDeviceSelectedState[0] );

    if ( typeDeviceSelectedState[0] === 'ap' ) {
      url = url !== '' ? `${url}&type_report=ap` : '';
    }

    // console.log( url );

    const dados = await getAllAction(`network-report${url}`);

    if ( dados.error === false ) {
      // console.log(dados.data);
      setReport(dados.data.result);
      setGlobalState('isLoadingState', false);

      mountTrafficReport(dados.data.result.trafficSummary);
      mountTrafficDistribution(dados.data.result.trafficDistribution);

      if ( typeDeviceSelectedState[0] === 'ap' ) {
        mountTopFiveApTraffic(dados.data.result.topTrafficAp);
        mountTopFiveApClientsTraffic(dados.data.result.topClientNumAp);
        mountTopFiveSSIDTraffic(dados.data.result.topTrafficSsid);
        mountTopFiveSSIDClientsTrafficDistribution(dados.data.result.topClientNumSsid);
        mountWirellesClientsActivities(dados.data.result.wirelessClientsActivities);
      }
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  /**
   * Get Network Report ISP
   * @returns {Promise<void>}
   */
  const getNetworkReportISP = async () => {
    console.log( 'getNetworkReportISP' );

    let url = getUrlCallOmada();
    url = url !== '' ? `${url}&type_report=isp&site_id=${siteSelectedState[0]}` : '';
    url = url !== '' ? `${url}&time_report=${timeReport}&date_start=${dateStart.timeStamp}&date_end=${dateEnd.timeStamp}` : '';
    // console.log( url );

    const dados = await getAllAction(`network-report${url}`);

    if ( dados.error === false ) {
      console.log(dados.data);
      mountIspLoad(dados.data.result.ispLoad[0]);
      mountWiredClients(dados.data.result.wiredClientsActivities.wiredClientsActivitiesList);
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  /**
   * Get Statistics
   * @returns {Promise<void>}
   */
  const getStatistics = async () => {
    console.log( 'getStatistics' );

    let url = getUrlCallOmada();
    url = url !== '' ? `${url}&gateway_mac=${gatewaySiteSelectedState[0]}&site_id=${siteSelectedState[0]}` : '';
    url = url !== '' ? `${url}&time_report=${timeReport}&date_start=${dateStart.timeStamp}&date_end=${dateEnd.timeStamp}` : '';

    const dados = await getAllAction(`statistics${url}`);

    if ( dados.error === false ) {
      // console.log(dados.data);
      mountStatistics(dados.data.result);
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  /**
   *
   */
  const changeTimeReport = () => {
    console.log( 'changeTimeReport' );
    // console.log( timeReport );

    const dateJSEnd = dayjs(new Date());

    setMinDateStart(dateJSEnd.subtract(30, 'days'));

    if ( timeReport === '0' ) {
      // console.log( 'timeReport 0' );
      setMinDateStart(dateJSEnd.subtract(2, 'days'));
    }

    if ( timeReport === '1' ) {
      // console.log( 'timeReport 1' );
      setMinDateStart(dateJSEnd.subtract(12, 'days'));
    }

  }

  /**
   *
   */
  useEffect(() => {
    console.log( 'isLoggedInState[0]' );
    if ( isLoggedInState[0] === true && siteSelectedState[0] !== null ) {
      changeTimeReport();
      getNetworkReport();
      getStatistics();

      setTimeout(() => {
        getNetworkReportISP();
      },10000);
    }
  }, [isLoggedInState[0]]);

  /**
   *
   * @param event
   */
  useEffect(() => {
    console.log( 'siteSelectedState[0]' );
    if ( isLoggedInState[0] === true && siteSelectedState[0] !== null ) {
      changeTimeReport();
      getNetworkReport();
      getStatistics();

      setTimeout(() => {
        getNetworkReportISP();
      },10000);
    }
  }, [siteSelectedState[0]]);

  /**
   *
   * @param event
   */
  const handleDateStart = (dataJS) => {
    console.log( 'handleDateStart' );
    console.log( dataJS.format('YYYY-MM-DD') );
    setDateStart({value: dataJS.format('YYYY-MM-DD'), timeStamp: dateToTimeStamp(`${dataJS.format('YYYY-MM-DD')} 00:00:00`)});
  }

  /**
   *
   * @param event
   */
  const handleDateEnd = (dataJS) => {
    //
    console.log( 'handleDateEnd' );
    console.log( dataJS.format('YYYY-MM-DD') );

    //
    setDateEnd({value: dataJS.format('YYYY-MM-DD'), timeStamp: dateToTimeStamp(`${dataJS.format('YYYY-MM-DD')} 23:59:59.999`)});

    //
    const date1 = dayjs(dateStart.value);
    const date2 = dayjs(dataJS);
    const compare = date2.diff(date1);

    //
    // console.log( 'dateCompare' );
    // console.log( compare );

    //
    if ( compare < 1 ) {
      setDateStart({value: dataJS.format('YYYY-MM-DD'), timeStamp: dateToTimeStamp(`${dataJS.format('YYYY-MM-DD')} 00:00:00`)});
    }

    //
    setMaxDateStart(dataJS);
  }

  const ajusteSelectTempo = () => {
    //
    console.log( 'ajusteSelectTempo' );
    // console.log( timeReport );

    const date1 = dayjs(dateStart.value);
    const date2 = dayjs(dateEnd.value);
    const date3 = dayjs(formatDate(new Date()));
    const compare = date2.diff(date1);
    const compare3 = date3.diff(date2);

    // console.log( 'compare3' );
    // console.log( compare3 );

    setTimeReportOptions(timeReportOptionsDefault);

    if ( compare > 172800000 ) {
      console.log( 'compare:: mais que dois dias' );
      // console.log( date1.format('YYYY-MM-DD') );
      // console.log( date2.format('YYYY-MM-DD') );

      setTimeReportOptions([
        {value: "1", label: "Por Hora"},
        {value: "2", label: "Por dia"}
      ]);

      if ( timeReport === 0 ) {
        setTimeReport((prevState) => {
          console.log(prevState); // aqui você pode ver o estado atualizado
          return 1;
        });
      }
    }

    if ( compare > 5184000000 ) {
      console.log( 'compare:: mais que seis dias' );
      // console.log( date1.format('YYYY-MM-DD') );
      // console.log( date2.format('YYYY-MM-DD') );

      setTimeReportOptions([
        {value: "2", label: "Por dia"}
      ]);

      if ( timeReport !== 2 ) {
        setTimeReport((prevState) => {
          console.log(prevState); // aqui você pode ver o estado atualizado
          return 2;
        });
      }
    }

    if ( compare3 > 259200000 ) {
      console.log( 'compare3:: mais que tres dias' );
      // console.log( date2.format('YYYY-MM-DD') );
      // console.log( date3.format('YYYY-MM-DD') );

      setTimeReportOptions([
        {value: "1", label: "Por Hora"},
        {value: "2", label: "Por dia"}
      ]);

      if ( timeReport === 0 ) {
        // console.log( 'caiu no if compare3 igual a hora' );
        setTimeReport((prevState) => {
          console.log(prevState); // aqui você pode ver o estado atualizado
          return 1;
        });
      }
    }

    if ( compare3 > 518400000 ) {
      console.log( 'compare3:: mais que seis dias' );
      // console.log( date2.format('YYYY-MM-DD') );
      // console.log( date3.format('YYYY-MM-DD') );
      // console.log( date2.subtract(1, 'day').format('YYYY-MM-DD') );

      setTimeReportOptions([
        {value: "2", label: "Por dia"}
      ]);

      if ( timeReport !== 2 ) {
        setTimeReport((prevState) => {
          console.log(prevState); // aqui você pode ver o estado atualizado
          return 2;
        });
      }
    }

  }

  /**
   *
   */
  useEffect(() => {
    //
    ajusteSelectTempo();

    //
    changeTimeReport();

    //
    getNetworkReport();

    //
    getStatistics();

    //
    setTimeout(() => {
      getNetworkReportISP();
    },10000);
  }, [dateStart, dateEnd]);

  /**
   *
   * @param event
   */
  const handleChangeTimeReport = (event) => {
    event.preventDefault();
    console.log( 'handleChangeTimeReport' );

    // setTimeReport(event.target.value);
    setTimeReport((prevState) => {
      console.log(prevState); // aqui você pode ver o estado atualizado
      return event.target.value;
    });

    timeReportOptions.forEach((item) => {
      if ( item.value === event.target.value ) {
        setDescriptionCharts(`Informações geradas ${item.label.toLowerCase()}`);

        if ( item.value === '2' ) {
          setDescriptionChartsWiredClients(`Informações geradas por hora`);
        } else {
          setDescriptionChartsWiredClients(`Informações geradas ${item.label.toLowerCase()}`);
        }
      }
    });

    changeTimeReport();
  }

  /**
   *
   */
  useEffect(() => {
    changeTimeReport();
    getNetworkReport();
    getStatistics();
    getNetworkReportISP();
  }, [timeReport]);

  /**
   *
   */
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <div style={{float:'left', marginRight: '10px', marginBottom: '15px'}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Inicio"
              value={dateStart.value}
              minDate={minDateStart}
              maxDate={maxDateStart}
              onChange={handleDateStart}
              renderInput={(params) => <TextField {...params} helperText={null} sx={{svg: { color: '#afb1b8' }, input: { color: '#afb1b8' },}} />}
            />
          </LocalizationProvider>
        </div>
        <div style={{float:'left', marginRight: '10px', marginBottom: '15px'}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Inicio"
              value={dateEnd.value}
              minDate={minDateEnd}
              maxDate={maxDateEnd}
              onChange={handleDateEnd}
              renderInput={(params) => <TextField {...params} helperText={null} sx={{svg: { color: '#afb1b8' }, input: { color: '#afb1b8' },}} />}
            />
          </LocalizationProvider>
        </div>

        <FormControl>
          <InputLabel id="demo-simple-select-label">Tempo</InputLabel>
          <Select
            style={{minWidth: '100px', width: '100%', height: '44px'}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={timeReport}
            label="Tempo"
            onChange={handleChangeTimeReport}
          >
            { timeReportOptions && timeReportOptions.map((timeOption, index) => (
              <MenuItem label={timeOption.label} key={timeOption.value} value={timeOption.value}>{timeOption.label}</MenuItem>
            )) }
          </Select>
        </FormControl>

      </MDBox>
      <MDBox py={3}>
        { report !== null && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardReport
                color="dark"
                icon="devices"
                title="Gateway"
                count={report.deviceSummary.gateway}
                percentage={{
                  color: "secondary",
                  amount: "Modelo",
                  label: `${report.deviceSummary.gatewayModel}`,
                }}
                percentageTwo={{
                  color: "success",
                  amount: "",
                  label: "",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardReport
                icon="dns"
                title="Total de Switches"
                count={report.deviceSummary.totalSwitches}
                percentage={{
                  color: "success",
                  amount: "Switches Ligados:",
                  label: `${report.deviceSummary.connectedSwitches}`,
                }}
                percentageTwo={{
                  color: "warning",
                  amount: "Switches Desligados:",
                  label: `${report.deviceSummary.disconnectedSwitches}`,
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardReport
                color="success"
                icon="router"
                title="Total de EAPs"
                count={report.deviceSummary.totalAps}
                percentage={{
                  color: "success",
                  amount: "EAPs Ligados:",
                  label: `${report.deviceSummary.connectedAps}`,
                }}
                percentageTwo={{
                  color: "warning",
                  amount: "EAPs Desligados:",
                  label: `${report.deviceSummary.disconnectedAps}`,
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardReport
                color="primary"
                icon="person_add"
                title="Clientes"
                count={report.clientsSummary.totalClients}
                percentage={{
                  color: "white",
                  amount: "Clientes Cabo:",
                  label: `${report.clientsSummary.wiredClients}`,
                }}
                percentageTwo={{
                  color: "white",
                  amount: "Clientes Wi-Fi:",
                  label: `${report.clientsSummary.wirelessClients}`,
                }}
                linkUrl="/admin/clients"
              />
            </MDBox>
          </Grid>
        </Grid>
        )}
        { report !== null && (
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <DefaultDoughnutChart
                  color="dark"
                  title="Resumo de tráfego"
                  description={`Tráfego total: ${formatBytes(report.trafficSummary.totalTraffic, 2)}`}
                  chart={{
                    labels: ["Tráfego Tx UpStream", "Tráfego Rx DownStream"],
                    datasets: {
                      label: "Projects",
                      backgroundColors: ["success", "warning"],
                      data: [formatBytes(report.trafficSummary.txTraffic, 2, false), formatBytes(report.trafficSummary.rxTraffic, 2, false)],
                    },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <MDBox mb={3}>
                <GradientLineChart
                  color="dark"
                  title="Tráfego Tx - UpStream (MB) / DownStream Tráfego Rx (MB)"
                  description={descriptionCharts}
                  chart={dataTraffic}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        )}
        { report !== null && (
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <DefaultDoughnutChart
                  color="dark"
                  title="Distribuição do Tráfego"
                  description={`Tráfego total: ${formatBytes(report.trafficDistribution.totalTraffic, 2)}`}
                  chart={{
                    labels: ["Tráfego com fios", "Tráfego sem fios"],
                    datasets: {
                      label: "Projects",
                      backgroundColors: ["info", "primary"],
                      data: [formatBytes(report.trafficDistribution.wiredTraffic, 2, false), formatBytes(report.trafficDistribution.wirelessTraffic, 2, false)],
                    },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <MDBox mb={3}>
                <GradientLineChart
                  color="dark"
                  title="Tráfego com fios (MB) / Tráfego sem fios (MB)"
                  description={descriptionCharts}
                  chart={dataTrafficDistribution}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        )}
        { dataStatistics.labels.length > 0 && (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={3}>
                  <GradientLineChart
                    color="dark"
                    title="Pacotes "
                    description="Packets Tx (Enviados) / Packets Rx (Recebidos)"
                    chart={dataStatistics}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}
        { (dataWiredClients.labels.length > 0 && typeDeviceSelectedState[0] !== 'ap') && (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={3}>
                  <GradientLineChart
                    color="dark"
                    title="Clientes ativos conectados "
                    description={descriptionChartsWiredClients}
                    chart={dataWiredClients}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}
        { (ispLoad.labels.length > 0 && typeDeviceSelectedState[0] !== 'ap') && (
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                <MixedChart
                  color="dark"
                  title="Carga ISP"
                  description="Dados da WAN ( Throughput (Mbps) / Latência (msec))"
                  chart={ispLoad}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        )}

        { (typeDeviceSelectedState[0] === 'ap') && (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={3}>
                  <GradientLineChart
                    color="dark"
                    title="Top 5 APs (Traffic)"
                    description="em (MB)"
                    chart={dataTopFiveApTrafficDistribution}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}

        { (typeDeviceSelectedState[0] === 'ap') && (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={3}>
                  <GradientLineChart
                    color="dark"
                    title="Top 5 APs (Total Clients)"
                    description="Clientes conectados"
                    chart={dataTopFiveApClientsTrafficDistribution}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}

        { (typeDeviceSelectedState[0] === 'ap' && report !== null) && (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <DefaultDoughnutChart
                    color="dark"
                    title="Resumo por APs"
                    description={`Tráfego total: ${formatBytes(report.apSummary.totalApTraffic, 2)}`}
                    chart={{
                      labels: getApSummaryNames(report.apSummary.apTraffic),
                      datasets: {
                        label: "Projects",
                        backgroundColors: ["primary", "secondary", "info", "success", "warning", "error", "light", "dark"],
                        data: getApSummaryData(report.apSummary.apTraffic)
                      },
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <DefaultDoughnutChart
                    color="dark"
                    title="Resumo por SSID"
                    description={`Tráfego total: ${formatBytes(report.ssidSummary.totalSsidTraffic, 2)}`}
                    chart={{
                      labels: getApSummaryNames(report.ssidSummary.ssidTraffic),
                      datasets: {
                        label: "Projects",
                        backgroundColors: ["primary", "secondary", "info", "success", "warning", "error", "light", "dark"],
                        data: getApSummaryData(report.ssidSummary.ssidTraffic)
                      },
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <DefaultDoughnutChart
                    color="dark"
                    title="Resumo por Banda"
                    description={`Tráfego total: ${formatBytes(report.bandSummary.totalTraffic, 2)}`}
                    chart={{
                      labels: getBandSummaryNames(report.bandSummary),
                      datasets: {
                        label: "Projects",
                        backgroundColors: ["primary", "secondary", "info", "success", "warning", "error", "light", "dark"],
                        data: getBandSummaryData(report.bandSummary)
                      },
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}

        { (typeDeviceSelectedState[0] === 'ap') && (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={3}>
                  <GradientLineChart
                    color="dark"
                    title="Top 5 SSIDs (Traffic)"
                    description="em (MB)"
                    chart={dataTopFiveSSIDTrafficDistribution}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}

        { (typeDeviceSelectedState[0] === 'ap') && (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={3}>
                  <GradientLineChart
                    color="dark"
                    title="Top 5 SSIDs (Total Clients)"
                    description="Clientes conectados"
                    chart={dataTopFiveSSIDClientsTrafficDistribution}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}

        { (typeDeviceSelectedState[0] === 'ap') && (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={3}>
                  <GradientLineChart
                    color="dark"
                    title="Wireless Client Activities"
                    description="Clientes conectados"
                    chart={dataWirellessClientsActivities}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Reports;
