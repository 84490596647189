/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

//
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../components/MDButton";

//
import {getUrlCallOmada, handleError} from "../../../../services/OmadaServices";
import {getAllAction, patchAction} from "../../../../services/ApiServices";
import {setGlobalState} from "../../../../services/StoreServices";
import {setCookie} from "../../../../services/Functions";

//
import ComplexStatisticsCardDashboardGateways from "./gateways";
import ComplexStatisticsCardDashboardEAPs from "./eaps";

function ComplexStatisticsCardDashboard({ colorData, title, countData, icon, siteId, typeDevice, gatewayMacDevice, locateDevice }) {
  //
  const navigate = useNavigate();

  //
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEAPs, setIsLoadingEAPs] = useState(true);
  const [portStats, setPortStats] = useState([]);
  const [portStateDetails, setPortStateDetails] = useState({
    wan: {exists: false},
    wan_lan_1: {exists: false},
    geral: {exists: false},
  });
  const [portStateDetailsLoading, setPortStateDetailsLoading] = useState(true);
  const [gatewayInfo, setGatewayInfo] = useState({});
  const [eapsInfo, setEapsInfo] = useState([]);
  const [locateAp, setLocateAp] = useState(locateDevice);

  //
  const [color, setColor] = useState(colorData);
  const [count, setCount] = useState(countData);

  //
  const [testResult, setTestResult] = useState(null);
  const [isTesting, setIsTesting] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if ( open === true && isTesting === false ) {
      setOpen(false);
    } else {
      alert("Por favor, aguarde o teste terminar.");
    }
  }

  //
  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#42424a',
    zIndex: 9,
    opacity: 1,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  /**
   * INIT
   */
  useEffect(() => {
    console.log(`ComplexStatisticsCardDashboard Mounted: ${title}`);
  }, []);

  const getValueUtilizationMB = (mb, percent) => {

    // Valores fornecidos
    const valorTotal = mb; // em MB
    const porcentagem = percent; // em %

    // Calculando o valor correspondente em MB
    const valorCorrespondenteMB = (porcentagem * valorTotal) / 100;
    // console.log(`% de ${valorTotal}MB é igual a ${valorCorrespondenteMB}MB`);

    return valorCorrespondenteMB;
  }

  /**
   *
   * @param data
   */
  const mountPortStateDetails = (portStateDetailsData) => {
    // console.log( `mountPortStateDetails: ${title}` );

    const status = {
      wan: {exists: false},
      wan_lan_1: {exists: false},
      geral: {exists: false},
    }

    portStateDetailsData.portInfos.forEach((item) => {
      switch (item.portName) {
        case 'WAN':
          status.wan = item;
          status.wan.exists = true;

          status.wan.rxUtilizationMB = getValueUtilizationMB(item.rxBandwidth, item.rxUtilization);
          status.wan.txUtilizationMB = getValueUtilizationMB(item.txBandwidth, item.txUtilization);

          break;

        case 'WAN/LAN1':
          status.wan_lan_1 = item;

          if ( item.rxUtilization !== undefined && item.txUtilization !== undefined ) {
            status.wan_lan_1.exists = true;
            status.wan_lan_1.rxUtilizationMB = getValueUtilizationMB(item.rxBandwidth, item.rxUtilization);
            status.wan_lan_1.txUtilizationMB = getValueUtilizationMB(item.txBandwidth, item.txUtilization);
          }

          break;

        default:
          break;
      }
    });

    if ( portStateDetailsData.utilization ) {
      status.geral = {
        utilization: portStateDetailsData.utilization,
        bandwidth: portStateDetailsData.bandwidth,
        exists: true
      };
    }

    // console.log( status );
    setPortStateDetails(status);
    setPortStateDetailsLoading(false);
  }

  /**
   * @param data
   * @returns {Promise<void>}
   */
  const  getPortStateDetails = async () => {
    // console.log( `getPortStateDetails - ${title}` );

    setPortStateDetailsLoading(true);

    let url = getUrlCallOmada();
    url = url !== '' ? `${url}&&site_id=${siteId}` : '';

    const dados = await getAllAction(`port-state-details${url}`);

    if ( dados.error === false ) {
      console.log( `getPortStateDetails - ${title}` );
      console.log( dados.data );

      mountPortStateDetails(dados.data.result);

      // setTimeout(() => {
        // console.log( `setTimeout - mountPortStateDetails - ${title}` );
      //  getPortStateDetails();
      // },60000);
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  /**
   *
   * @param data
   */
  const mountGatewayVars = (portStatsData,dhcpSettingsData=null) => {
    // console.log( `mountGatewayVars: ${title}` );

    const status = {
      usb: {},
      wan: {},
      wan_lan_1: {},
      wan_lan_2: {},
      lan_1: {},
      lan_2: {},
    };

    portStatsData.forEach((item) => {
      // console.log( item );

      switch (item.name) {
        case 'USB MODEM':
          status.usb = item;
          break;

        case 'WAN':
          status.wan = item;
          break;

        case 'WAN/LAN1':
          status.wan_lan_1 = item;
          break;

        case 'WAN/LAN2':
          status.wan_lan_2 = item;
          if ( dhcpSettingsData !== null && item.status === 1 ) {
            status.wan_lan_2.dhcpSettings = dhcpSettingsData;
          }
          break;

        case 'LAN1':
          status.lan_1 = item;
          if ( dhcpSettingsData !== null && item.status === 1 ) {
            status.lan_1.dhcpSettings = dhcpSettingsData;
          }
          break;

        case 'LAN2':
          status.lan_2 = item;
          if ( dhcpSettingsData !== null && item.status === 1 ) {
            status.lan_2.dhcpSettings = dhcpSettingsData;
          }
          break;

        default:
          break;
      }
    });

    // console.log( status );
    setPortStats(status);
    setIsLoading(false);
  }

  /**
   *
   * @param gatewayMac
   * @returns {Promise<void>}
   */
  const  getGateways = async (gatewayMac) => {
    // console.log( `getGateways - ${title}` );

    let url = getUrlCallOmada();
    url = url !== '' ? `${url}&gateway_mac=${gatewayMac}&site_id=${siteId}` : '';

    const dados = await getAllAction(`gateways${url}`);

    if ( dados.error === false ) {
      // console.log( dados.data );
      // console.log( dados.data.result.portStats );

      setGatewayInfo(dados.data.result);
      mountGatewayVars(dados.data.result.portStats, (dados.data.result.dhcpSettings?dados.data.result.dhcpSettings:null));
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  /**
   *
   */
  const  getEAPs = async (gatewayMac) => {
    // console.log( `getEAPs - ${title} - ${gatewayMac}` );

    let url = getUrlCallOmada();
    url = url !== '' ? `${url}&gateway_mac=${gatewayMac}&site_id=${siteId}` : '';

    const dados = await getAllAction(`eaps${url}`);

    if ( dados.error === false ) {
      // console.log( dados.data.result );

      setEapsInfo(dados.data.result);

      if ( dados.data.result.uptimeLong > 0 ) {
        setColor('success');
      }

      if ( dados.data.result.locateEnable === true ) {
        setLocateAp(true);
      }

      setIsLoadingEAPs(false);
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  /**
   *
   * @returns {Promise<void>}
   */
  const getDevices = () => {

    if ( typeDevice === 'gateway') {
      getGateways(gatewayMacDevice);
    }

    if ( typeDevice === 'ap' ) {
      getEAPs(gatewayMacDevice);
    }

    // setTimeout(() => {
      // console.log( `timeout 2 minutes:: ${title}` ); // 120000
    //  getDevices();
    // },120000);

  }

  /**
   *
   */
  useEffect(() => {
    if ( isLoading === true ) {
      getDevices();

      getPortStateDetails();
    }
  }, []);

  /**
   *
   * @param item
   * @returns {string}
   */
  const getColor = (item) => {
    let colorStatus = 'inherit';

    if ( item.status === 1 ) {
      colorStatus = 'success';

      if ( item.speed < 3 ) {
        colorStatus = 'warning';
      }

      if ( item.rxRate === 0 && item.txRate === 0 && item.internetState === 0 ) {
        colorStatus = 'error';
      }

      if ( item.rxRate === 0 && item.txRate === 0 && item.internetState === 1 ) {
        colorStatus = 'azulClaro';
      }

    } else if ( item.status === 0 ) {
      colorStatus = 'secondary';
    }

    return colorStatus;
  }

  /**
   *
   * @param item
   * @returns {string}
   */
  const getColorIcon = (item) => {
    const colorIcon = getColor(item);

    switch (colorIcon) {
      case 'success':
        return '#4caf4f';

      case 'warning':
        return '#ff9900';

      case 'error':
        return '#f44336';

      case 'secondary':
        return '#7b809a';

      case 'inherit':
        return '#1a2035';

      case 'azulClaro':
        return '#37a5b6';

      default:
        return '#1a2035';
    }
  }

  /**
   *
   * @param event
   */
  const openReport = (event) => {
    event.preventDefault();
    setGlobalState('siteSelectedState', siteId);
    setCookie('siteSelectedCk', siteId, 1);
    setGlobalState('gatewaySiteSelectedState', gatewayMacDevice);
    setCookie('gatewaySiteSelectedCk', gatewayMacDevice, 1);
    setGlobalState('typeDeviceSelectedState', typeDevice);
    setCookie('typeDeviceSelectedCk', typeDevice, 1);
    navigate("/admin/reports")
  }

  const openClientes = (event) => {
    event.preventDefault();
    setGlobalState('siteSelectedState', siteId);
    setCookie('siteSelectedCk', siteId, 1);
    setGlobalState('gatewaySiteSelectedState', gatewayMacDevice);
    setCookie('gatewaySiteSelectedCk', gatewayMacDevice, 1);
    setGlobalState('typeDeviceSelectedState', typeDevice);
    setCookie('typeDeviceSelectedCk', typeDevice, 1);
    navigate("/admin/clients")
  }

  /**
   *
   * @returns {Promise<void>}
   */
  const getResulTest = async () => {
    // console.log( 'getResulTest' );

    const url = getUrlCallOmada('Admin');
    const dados = await getAllAction(`speed-test/${siteId}${url}`);

    if ( dados.error === false ) {
      // console.log( dados.data );

      if ( dados.data.result.speedTestStatus === 2 ) {
        setTimeout(() => {
          getResulTest();
        },4500);
      } else {
        // console.log( 'dados.data.result.speedTestResults' );
        // console.log( dados.data.result.speedTestResults );
        setTestResult(dados.data.result.speedTestResults);
        setIsTesting(false);
      }
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  /**
   *
   * @returns {Promise<void>}
   */
  const startTest = async () => {
    let url = getUrlCallOmada('Admin');
    url = url !== '' ? `${url}&site_id=${siteId}` : '';

    const dados = await getAllAction(`speed-test${url}`);

    if ( dados.error === false ) {
      // console.log( dados.data );
      setTimeout(() => {
        getResulTest();
      },3000);
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  /**
   *
   * @param event
   */
  const speedTest = (event) => {
    event.preventDefault();
    handleOpen();
    startTest();
  }

  const disableSpeedTest = () => {
    if ( portStats.wan && (portStats.wan.status === 0 && portStats.wan_lan_1.status === 0)) {
      return true;
    }

    return false;
  }

  /**
   *
   */
  const locateApAction = async () => {
    console.log( 'locateAp' );

    let tempLocateAp= locateAp;
    if ( locateAp === false ) {
      tempLocateAp = true;
    } else {
      tempLocateAp = false;
    }

    let url = getUrlCallOmada('Admin');
    url = url !== '' ? `${url}&site_id=${siteId}&gateway_mac=${gatewayMacDevice}` : '';

    const dados = await patchAction(`eaps/locate${url}`, { locateEnable: tempLocateAp });

    if ( dados.error === false ) {
      console.log( dados.data );
    }

    if ( dados.error === true ) {
      tempLocateAp = false;
      handleError(dados);
    }

    setLocateAp(tempLocateAp);

  }

  /**
   * @returns {JSX.Element}
   */
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const [openToolTip, setOpenToolTip] = useState({
    wan1: false,
    wan2: false,
    lan1: false,
    lan2: false,
    lan3: false,
  });

  const handleTooltipClose = () => {
    // setOpenToolTip(false);

    setOpenToolTip({
      wan1: false,
      wan2: false,
      lan1: false,
      lan2: false,
      lan3: false,
    });

  };

  const handleTooltipOpen = (event, port) => {

    event.preventDefault();

    switch (port) {
      case 'wan1':
        setOpenToolTip({wan1: true});
        break;

      case 'wan2':
        setOpenToolTip({wan2: true});
        break;

      case 'lan1':
        setOpenToolTip({lan1: true});
        break;

      case 'lan2':
        setOpenToolTip({lan2: true});
        break;

      case 'lan3':
        setOpenToolTip({lan3: true});
        break;

      default:
        setOpenToolTip({
          wan1: false,
          wan2: false,
          lan1: false,
          lan2: false,
          lan3: false,
        });
        break;
    }

    // setOpenToolTip(true);
    // alert('TESTE');
  };

  /**
   * @returns {JSX.Element}
   */
  return (
    <Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>

          <Icon fontSize="medium"
                color="action"
                onClick={handleClose}
                style={{position: 'absolute', cursor: 'pointer', left: 'auto', right: '10px', top: '10px'}}>
            close
          </Icon>

          <MDTypography variant="h6" component="h2">
            Teste de Velocidade
          </MDTypography>
          {isTesting === true &&
            <MDTypography color="text" sx={{ mt: 2 }}>
              Testando...
            </MDTypography>
          }
          {isTesting === false &&
            <MDBox sx={{ mt: 2 }}>
              <MDBox sx={{ mt: 2 }}>
                <MDTypography color="success" fontWeight="bold" variant="body2" component="p">
                  WAN
                </MDTypography>
                <MDTypography color="text" variant="body2" component="p">
                  Velocidade de Download: <MDTypography fontWeight="bold" variant="body2" component="span">{testResult[0]?testResult[0].down:''} Mbps</MDTypography>
                </MDTypography>
                <MDTypography color="text" variant="body2" component="p">
                  Velocidade de Upload: <MDTypography fontWeight="bold" variant="body2" component="span">{testResult[0]?testResult[0].up:''} Mbps</MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox sx={{ mt: 2 }}>
                <MDTypography color="success" fontWeight="bold" variant="body2" component="p">
                  WAN/LAN1
                </MDTypography>
                <MDTypography color="text" variant="body2" component="p">
                  Velocidade de Download: <MDTypography fontWeight="bold" variant="body2" component="span">{testResult[1]?testResult[1].down:''} Mbps</MDTypography>
                </MDTypography>
                <MDTypography color="text" variant="body2" component="p">
                  Velocidade de Upload: <MDTypography fontWeight="bold" variant="body2" component="span">{testResult[1]?testResult[1].up:''} Mbps</MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          }
        </Box>
      </Modal>

      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
          style={{cursor:'pointer'}}
          onClick={openReport}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25} style={{cursor:'pointer'}} onClick={openClientes}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          <MDTypography variant="h4"><span style={{fontSize:'11px'}}>CLIENTES</span> {count}</MDTypography>
        </MDBox>
      </MDBox>
      { isLoading === true && isLoadingEAPs === true &&
        <MDBox pb={2} px={2}>
          <MDTypography component="p" variant="button" color="text" display="flex">
            Carregando...
          </MDTypography>
        </MDBox>
      }
      { isLoading === false && portStats.usb !== null &&
        <ComplexStatisticsCardDashboardGateways portStats={portStats} />
      }
      { isLoadingEAPs === false && eapsInfo.site !== null &&
        <ComplexStatisticsCardDashboardEAPs eapsInfo={eapsInfo} />
      }
      <Divider />
      <MDBox pb={2} px={2}>
        {/*
          <MDButton color={disableSpeedTest()===false?'info':'dark'} variant="contained" size="small" onClick={speedTest} disabled={disableSpeedTest()}>
            Teste de velocidade
          </MDButton>
        */}
        { portStats.wan && portStats.wan.wanPortIpv4Config ?

          <>
            <MDBox style={{display:(portStateDetailsLoading?'block':'none')}}>
              Atualizando...
            </MDBox>

            <MDBox style={{minWidth: '350px', overflowX: 'hidden', display:(portStateDetailsLoading?'none':'block')}}>

              <Grid container spacing={2} className="saudelink-ajuste-grid-titulo">
                <Grid item sm={1} md={1} xs={2} lg={2} xl={2} xxl={2} className="saudelink-ajuste-grid-porta-coluna">
                  <MDTypography component="p" variant="button" fontWeight="medium" display="flex">
                    PORTA
                  </MDTypography>
                </Grid>
                <Grid item sm={1} md={1} xs={5} lg={2} xl={3} xxl={5}>
                  <MDTypography component="p" variant="button" fontWeight="medium" display="flex">
                    BANDA AGORA
                  </MDTypography>
                </Grid>
                <Grid item sm={1} md={1} xs={4} lg={2} xl={4} xxl={4}>
                  <MDTypography component="p" variant="button" fontWeight="medium" display="flex">
                    STATUS GW&nbsp;
                    <MDTypography
                      component="span"
                      variant="button"
                      fontWeight="bold"
                      color="success"
                    >
                      {portStateDetails.geral?portStateDetails.geral.utilization:''}%
                    </MDTypography>
                  </MDTypography>
                </Grid>
              </Grid>

              <MDBox style={{display:(portStateDetails.wan.exists===true?'flex':'none')}}>
                <Grid className="saudelink-ajuste-grid" container spacing={2} flexDirection={{ xs: "row", sm: "row", md: "row", lg: "column", xl: "row", xll: "row" }}>
                  <Grid item sm={1} md={1} xs={2} lg={2} xl={2} xxl={2} className="saudelink-ajuste-grid-porta-coluna">
                    <MDTypography component="p" variant="button" color="text" display="flex">
                      <MDTypography
                        component="span"
                        variant="button"
                        fontWeight="bold"
                        fontSize="small"
                        className="saudelink-ajuste-grid-porta"
                        color={getColor(portStats.wan)}
                      >
                        WAN 1:
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  <Grid item sm={1} md={1} xs={5} xl={4} xxl={5} className="saudelink-ajuste-grid-item">
                    <MDTypography component="p" variant="button" color="text" display="flex" fontSize="small">
                      <Icon fontSize="small" color="info">keyboard_arrow_up</Icon> {portStateDetails.wan?portStateDetails.wan.txUtilizationMB:''}Mbps
                      <Icon fontSize="small" color="primary">keyboard_arrow_down</Icon> {portStateDetails.wan?portStateDetails.wan.rxUtilizationMB:''}Mbps
                    </MDTypography>
                  </Grid>
                  <Grid item sm={1} md={1} xs={4} xl={3} xxl={4} className="saudelink-ajuste-grid-item saudelink-ajuste-grid-item-porcentagem">
                    <MDTypography component="p" variant="button" color="text" display="flex" fontSize="small">
                      <Icon fontSize="small" color="info">keyboard_arrow_up</Icon> {portStateDetails.wan?portStateDetails.wan.txUtilization:''}%
                      <Icon fontSize="small" color="primary">keyboard_arrow_down</Icon> {portStateDetails.wan?portStateDetails.wan.rxUtilization:''}%
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox style={{display:(portStateDetails.wan_lan_1.exists===true?'flex':'none')}}>
                <Grid className="saudelink-ajuste-grid" container spacing={2} flexDirection={{ xs: "row", sm: "row", md: "row", lg: "column", xl: "row", xll: "row", xxxl: "row" }}>
                  <Grid item sm={1} md={1} xs={2} lg={2} xl={2} xxl={2} className="saudelink-ajuste-grid-porta-coluna">
                    <MDTypography component="p" variant="button" color="text" display="flex">
                      <MDTypography
                        component="span"
                        variant="button"
                        fontWeight="bold"
                        fontSize="small"
                        className="saudelink-ajuste-grid-porta"
                        color={getColor(portStats.wan_lan_1)}
                      >
                        WAN/LAN1:
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  <Grid item sm={1} md={1} xs={5} xl={4} xxl={5} className="saudelink-ajuste-grid-item">
                    <MDTypography component="p" variant="button" color="text" display="flex" fontSize="small">
                      <Icon fontSize="small" color="info">keyboard_arrow_up</Icon> {portStateDetails.wan_lan_1?portStateDetails.wan_lan_1.txUtilizationMB:''}Mbps
                      <Icon fontSize="small" color="primary">keyboard_arrow_down</Icon> {portStateDetails.wan_lan_1?portStateDetails.wan_lan_1.rxUtilizationMB:''}Mbps
                    </MDTypography>
                  </Grid>
                  <Grid item sm={1} md={1} xs={4} xl={3} xxl={4} className="saudelink-ajuste-grid-item saudelink-ajuste-grid-item-porcentagem">
                    <MDTypography component="p" variant="button" color="text" display="flex" fontSize="small">
                      <Icon fontSize="small" color="info">keyboard_arrow_up</Icon> {portStateDetails.wan_lan_1?portStateDetails.wan_lan_1.txUtilization:''}%
                      <Icon fontSize="small" color="primary">keyboard_arrow_down</Icon> {portStateDetails.wan_lan_1?portStateDetails.wan_lan_1.rxUtilization:''}%
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>

          </MDBox>
          </>

          :
          <MDButton color="error" variant="contained" size="small" style={{display: (isLoading?'none':'block')}}>
            Unidade Down! Verifique a energia e os links conectados
          </MDButton>
        }
        {isLoadingEAPs === false && eapsInfo.site !== null &&
          <MDButton color={locateAp?'success':'info'} variant="contained" size="small" onClick={locateApAction}>
            Locate
          </MDButton>
        }
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCardDashboard.defaultProps = {
  colorData: "info",
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCardDashboard.propTypes = {
  colorData: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  countData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.node.isRequired,
  siteId: PropTypes.string.isRequired,
  typeDevice: PropTypes.string.isRequired,
  gatewayMacDevice: PropTypes.string.isRequired,
  locateDevice: PropTypes.bool.isRequired,
};

export default ComplexStatisticsCardDashboard;
