import {useEffect, useState} from "react";

//
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenMYDocker,
} from "../../context";

//
import MYDocker from "../../components/MYDocker";
import {setGlobalState, useGlobalState} from "../../services/StoreServices";
import {getUrlCallOmada, handleError} from "../../services/OmadaServices";
import {getAllAction, patchAction} from "../../services/ApiServices";
import MDButton from "../../components/MDButton";

function EditClient() {

  const [controller, dispatch] = useMaterialUIController();
  const {
    openMYDocker,
    darkMode,
  } = controller;
  const [disabled, setDisabled] = useState(false);

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  //
  const [client, setClient] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limitRate, setLimitRate] = useState(false);
  const [nameClient, setNameClient] = useState('');
  const [limitDownload, setLimitDownload] = useState(false);
  const [limitDownloadValue, setLimitDownloadValue] = useState(0);
  const [limitUpload, setLimitUpload] = useState(false);
  const [limitUploadValue, setLimitUploadValue] = useState(0);

  //
  const handleCloseConfigurator = () => setOpenMYDocker(dispatch, false);

  useEffect(() => {
    setTimeout(() => {
      console.log("EditClient Mounted");
      // setOpenMYDocker(dispatch, true);
      // setIsLoading(true);
    },5000);
  }, []);

  //
  const siteSelectedState = useGlobalState("siteSelectedState");
  const clientSelectedState = useGlobalState("clientSelectedState");

  const getClient = async () => {
    setIsLoading(true);
    // console.log( 'getClient' );

    let url = getUrlCallOmada('Admin');
    url = url !== '' ? `${url}&site_id=${siteSelectedState[0]}` : '';
    // console.log( url );
    const dados = await getAllAction(`clients/${clientSelectedState[0].mac}${url}`);

    if ( dados.error === false ) {
      // console.log(dados.data);
      setClient(dados.data.result);
      setIsLoading(false);

      if ( dados.data.result ) {
        // console.log( 'dados.data.result.name' );
        // console.log( dados.data.result.name );
        setNameClient(dados.data.result.name);
        setLimitRate(dados.data.result.rateLimit.enable);
        setLimitDownload(dados.data.result.rateLimit.downEnable);
        setLimitDownloadValue(dados.data.result.rateLimit.downLimit);
        setLimitUpload(dados.data.result.rateLimit.upEnable);
        setLimitUploadValue(dados.data.result.rateLimit.upLimit);
      }

    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  useEffect(() => {
    if ( clientSelectedState[0] !== null ) {
      // console.log("Client Selected: ", clientSelectedState);
      getClient();
    }

  }, [clientSelectedState[0]]);

  const handleNameClient = (e) => {
    setNameClient(e.target.value);
  }

  const handleSetlimitRate = (e) => {
    setLimitRate(!limitRate);
    if ( limitRate === true ) {
      setLimitDownload(false);
      setLimitUpload(false);
    }
  }

  const handleSetlimitDownload = (e) => {
    setLimitDownload(!limitDownload);
  }

  const handleSetlimitUpload = (e) => {
    setLimitUpload(!limitUpload);
  }

  const handleSetlimitDownloadValue = (e) => {
    setLimitDownloadValue(e.target.value);
  }

  const handleSetlimitUploadValue = (e) => {
    setLimitUploadValue(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Submit");

    const dados = {
      "name": nameClient,
      "rateLimit": {
        "enable": limitRate,
        "downEnable": limitDownload,
        "downLimit": limitDownloadValue,
        "downUnit": 2,
        "upEnable": limitUpload,
        "upLimit": limitUploadValue,
        "upUnit": 2
      }
    }
    // console.log( dados );

    const url = getUrlCallOmada('Admin');

    const dadosUpt = await patchAction(`clients/${client.mac}${url}&site_id=${siteSelectedState[0]}`, dados);

    if ( dadosUpt.error === undefined || dadosUpt.error === false ) {
      setGlobalState('alertMsgState', {msg: 'Dados atualizados com sucesso!', min: 'agora', tit: 'Aviso'});
      setGlobalState('clientSelectedEditedState', {mac: client.mac, name: nameClient, time: Date.now()});
      getClient();
    }

    if ( dadosUpt.error === true ) {
      handleError(dadosUpt);
    }

  }

  return (
      <MYDocker variant="permanent" ownerState={{ openMYDocker }}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={4}
          pb={0.5}
          px={3}
        >
          <MDBox>
            <MDTypography variant="h5">Detalhes do cliente:</MDTypography>
            <MDTypography variant="body2" color="text">
              {clientSelectedState[0] !== null ? clientSelectedState[0].mac : ''}
            </MDTypography>
          </MDBox>

          <Icon
            sx={({ typography: { size }, palette: { dark, white } }) => ({
              fontSize: `${size.lg} !important`,
              color: darkMode ? white.main : dark.main,
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)",
            })}
            onClick={handleCloseConfigurator}
          >
            close
          </Icon>
        </MDBox>

        <Divider />

        {isLoading === false ? (
        <MDBox pt={0.5} pb={3} px={3}>
          <MDBox>
            <MDTypography variant="h6">Dados Gerais</MDTypography>

            <MDBox mt={2} mb={0.5}>
              <FormControl fullWidth>
                <MDBox display="flex">
                  <MDInput type="text" label="Nome" value={nameClient} onChange={handleNameClient} />
                </MDBox>

                <MDBox display="flex" alignItems="center" ml={-1} mt={1}>
                  <Switch checked={limitRate}
                          onChange={handleSetlimitRate}
                          color="warning"
                          inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetlimitRate}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Limitar taxa de transferência
                  </MDTypography>
                </MDBox>

                <MDBox display={limitRate?'flex':'none'} alignItems="center" ml={-1} mt={1}>
                  <Switch checked={limitDownload}
                          onChange={handleSetlimitDownload}
                          color="warning"
                          inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetlimitDownload}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Limite de download
                  </MDTypography>
                </MDBox>

                <MDBox display={limitDownload?'flex':'none'} mt={1}>
                  <MDInput type="text" label="Limite de download em Mbps" value={limitDownloadValue} onChange={handleSetlimitDownloadValue} />
                </MDBox>

                <MDBox display={limitRate?'flex':'none'} alignItems="center" ml={-1} mt={1}>
                  <Switch checked={limitUpload}
                          onChange={handleSetlimitUpload}
                          color="warning"
                          inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetlimitUpload}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Limite de upload
                  </MDTypography>
                </MDBox>

                <MDBox display={limitUpload?'flex':'none'} mt={1}>
                  <MDInput type="text" label="Limite de upload em Mbps" value={limitUploadValue} onChange={handleSetlimitUploadValue} />
                </MDBox>

                <MDBox display="flex" ml={-1} mt={3}>
                  <MDButton onClick={handleSubmit}
                            variant="contained"
                            color="info"
                            size="small">
                    Salvar
                  </MDButton>
                </MDBox>

              </FormControl>
            </MDBox>
          </MDBox>
        </MDBox>
        ) : (
          <MDBox pt={0.5} pb={3} px={3}>
            <MDTypography variant="h6">Carregando...</MDTypography>
          </MDBox>
        )}

      </MYDocker>
  );
}

export default EditClient;