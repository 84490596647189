import {useEffect, useState} from "react";
import { styled } from '@mui/material/styles';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

function ComplexStatisticsCardDashboardEAPs({eapsInfo}) {

  /**
   *
   * @param item
   * @returns {string}
   */
  const getColor = (item) => {
    let colorStatus = 'inherit';

    if ( item.status > 1 ) {
      colorStatus = 'success';

    } else if ( item.status === 0 ) {
      colorStatus = 'secondary';
    }

    return colorStatus;
  }

  /**
   *
   * @param item
   * @returns {string}
   */
  const getColorIcon = (item) => {
    const colorIcon = getColor(item);

    switch (colorIcon) {
      case 'success':
        return '#4caf4f';

      case 'warning':
        return '#ff9900';

      case 'error':
        return '#f44336';

      case 'secondary':
        return '#7b809a';

      case 'inherit':
        return '#1a2035';

      case 'azulClaro':
        return '#37a5b6';

      default:
        return '#1a2035';
    }
  }

  /**
   * @returns {JSX.Element}
   */
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const [openToolTip, setOpenToolTip] = useState({
    aps: false,
  });

  const handleTooltipClose = () => {
    setOpenToolTip({
      aps: false,
    });

  };

  const handleTooltipOpen = (event, port) => {
    event.preventDefault();

    switch (port) {
      case 'aps':
        setOpenToolTip({aps: true});
        break;

      default:
        setOpenToolTip({
          aps: false,
        });
        break;
    }
  };

  return (
    <MDBox textAlign="center">
      <MDBox display="flex" justifyContent="center" alignItems="center" mt={2}>

          <CustomWidthTooltip key={eapsInfo.name} title={
            <>
              <MDTypography fontWeight="light" fontSize="medium" color="inherit">Nome: <b>{eapsInfo.name}</b></MDTypography>
              <MDTypography fontWeight="light" fontSize="medium" color="inherit">IP: <b>{eapsInfo.ip}</b></MDTypography>
            </>
          } placeholder="bottom"
                              style={{cursor: 'pointer'}}
                              onClose={handleTooltipClose}
                              open={openToolTip.aps}
          >
            <MDBox ml={1} onClick={(e) => handleTooltipOpen(e, 'aps')}>
              <Icon fontSize="small" style={{color: getColorIcon(eapsInfo)}}>
                album
              </Icon>
              <MDTypography variant="button" color={getColor(eapsInfo)} fontWeight="light" ml={0.5} mr={1} position="relative" top={-5}>
                {eapsInfo.name}
              </MDTypography>
            </MDBox>
          </CustomWidthTooltip>

      </MDBox>

      <MDBox display="flex" justifyContent="center" alignItems="center" mt={2}>
        <MDBox ml={1}>
          <p>&nbsp;</p>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCardDashboardEAPs.defaultProps = {
  eapsInfo: {},
  color: "info",
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCardDashboardEAPs.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  eapsInfo: PropTypes.objectOf(PropTypes.object),
};

export default ComplexStatisticsCardDashboardEAPs;