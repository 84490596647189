/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

//
import {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCardDasboard from "examples/Cards/StatisticsCards/ComplexStatisticsCardDasboard";

//
import {getAllAction} from "../../services/ApiServices";
import {getUrlCallOmada, handleError} from "../../services/OmadaServices";
import {setGlobalState, useGlobalState} from "../../services/StoreServices";
import MDTypography from "../../components/MDTypography";
import breakpoints from "../../assets/theme/base/breakpoints";

function Dashboard() {
  //
  const isLoggedInState = useGlobalState('isLoggedInState');

  //
  const [sites, setSites] = useState([]);

  /**
   *
   */
  useEffect(() => {
    console.log("Dashboard Mounted");
  }, []);

  const [propsMobile, setPropsMobile] = useState({
    item: true,
    xs: 12,
    md: 6,
    lg: 4,
  });

  const propsNoMobile = {
    spacing: 3,
    display: 'flex',
    width: '85%',
    ml: '0',
    mr: 'auto'
  };

  /**
   *
   */
  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        console.log( 'mobile' );
      } else {
        setPropsMobile(propsNoMobile)
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  /**
   *
   * @returns {Promise<void>}
   */
  const getDevices = async (siteId) => {
    // console.log( `getDevices DASH - ${siteId}` );
    let retorno = [];

    let url = getUrlCallOmada();
    url = url !== '' ? `${url}&site_id=${siteId}` : '';

    const dados = await getAllAction(`devices${url}`);

    if ( dados.error === false ) {
      // console.log( dados.data.result );
      retorno = dados.data.result;
    }

    if ( dados.error === true ) {
      console.log( `getDevices DASH ERROR - ${siteId}` );
      console.log( dados );
      // handleError(dados);
    }

    return retorno;
  }

  /**
   *
   */
  const getDevicesPromise = (siteId) => new Promise((resolve, reject) => {
    try {
      const devices = getDevices(siteId);
      resolve(devices);
    } catch (error) {
      reject(error);
    }
  });

  /**
   *
   */
  const mountSites = async (sitesData) => {

    const devicesBySite = [];

    const newSites = await Promise.all(sitesData.map(async (site) => {
      try {
        const tempDevices = await getDevicesPromise(site.id);
        devicesBySite[site.id] = tempDevices;
        return { ...site, devices: devicesBySite[site.id] };
      } catch (error) {
        console.log( 'mountSites :: ERROR' );
        console.log( error );
        // console.error(error);
        return site;
      }
    }));

    setSites(newSites);
    setGlobalState('isLoadingState', false);
  }

  /**
   *
   * @returns {Promise<void>}
   */
  const getSites = async () => {
    setGlobalState('isLoadingState', true);

    const url = getUrlCallOmada('Admin');
    const dados = await getAllAction(`sites${url}`);

    if ( dados.error === false ) {
      // console.log( dados.data.result.data );
      mountSites(dados.data.result.data);

      // const login = JSON.parse(localStorage.getItem('dadosUser'));
      // console.log( 'login', login );

      const timer = 90000;
      // const timer = login.login==="root"?180000:90000;
      // console.log( 'timer', timer );

      setTimeout(() => {
        console.log( 'setTimeout - getSites' );
        getSites();
      },timer);
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  useEffect(() => {
    if ( isLoggedInState[0] === true ) {
      getSites();
    }
  }, [isLoggedInState[0]]);

  /**
   * RENDER
   */
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <Grid {...propsMobile} >
          <Grid display="flex">
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
              <MDBox
                variant="gradient"
                bgColor="success"
                color="success"
                coloredShadow="success"
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
                mt={-0.2}
                mr={1.5}
              >
                &nbsp;
              </MDBox>
              <MDBox textAlign="right" lineHeight={1.25}>
                <MDTypography variant="button" fontWeight="light" color="text">
                  Link Up / Gigabit
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>

          <Grid display="flex">
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
              <MDBox
                variant="gradient"
                bgColor="warning"
                color="warning"
                coloredShadow="warning"
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
                mt={-0.2}
                mr={1.5}
              >
                &nbsp;
              </MDBox>
              <MDBox textAlign="right" lineHeight={1.25}>
                <MDTypography variant="button" fontWeight="light" color="text">
                  Link Up / 10/100Mbps
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>

          <Grid display="flex">
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
              <MDBox
                variant="gradient"
                bgColor="azulClaro"
                color="azulClaro"
                coloredShadow="azulClaro"
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
                mt={-0.2}
                mr={1.5}
              >
                &nbsp;
              </MDBox>
              <MDBox textAlign="right" lineHeight={1.25}>
                <MDTypography variant="button" fontWeight="light" color="text">
                  Link Up / Sem Tráfego
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>

          <Grid display="flex">
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
              <MDBox
                variant="gradient"
                bgColor="error"
                color="error"
                coloredShadow="error"
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
                mt={-0.2}
                mr={1.5}
              >
                &nbsp;
              </MDBox>
              <MDBox textAlign="right" lineHeight={1.25}>
                <MDTypography variant="button" fontWeight="light" color="text">
                  Link Down
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>

          <Grid display="flex">
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
              <MDBox
                variant="gradient"
                bgColor="secondary"
                color="secondary"
                coloredShadow="secondary"
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
                mt={-0.2}
                mr={1.5}
              >
                &nbsp;
              </MDBox>
              <MDBox textAlign="right" lineHeight={1.25}>
                <MDTypography variant="button" fontWeight="light" color="text">
                  Desconectado
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>

          <Grid display="flex">
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
              <MDBox
                variant="gradient"
                bgColor="dark"
                color="dark"
                coloredShadow="dark"
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="2rem"
                height="2rem"
                mt={-0.2}
                mr={1.5}
              >
                &nbsp;
              </MDBox>
              <MDBox textAlign="right" lineHeight={1.25}>
                <MDTypography variant="button" fontWeight="light" color="text">
                  Desabilitado
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>

      </MDBox>

      <MDBox py={3}>
        { /* CUSTOM */ }
        <Grid container spacing={3}>
          {sites &&
            sites.flatMap((site) =>
              site.devices.map((device) => (
                <Grid item xs={12} md={6} lg={3} key={site.id}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCardDasboard
                      colorData={site.wan !== true ? 'error' : 'success'}
                      icon="leaderboard"
                      title={site.name}
                      countData={(device.type==="ap")?device.clientNum:site.lanUserNum}
                      siteId={site.id}
                      gatewayMacDevice={device.mac}
                      typeDevice={device.type}
                      locateDevice={device.locateEnable}
                    />
                  </MDBox>
                </Grid>
              ))
            )}
        </Grid>
        { /* CUSTOM */ }
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;