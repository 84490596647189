import {getAllAction} from "./ApiServices";
import {setGlobalState} from "./StoreServices";
import {deleteCookie, setCookie, getCookie} from "./Functions";

export const setAlertMsg = (message) => {
  setGlobalState('alertMsgState', message);
  // console.log( 'setAlertMsg - message' );
  // console.log( message );
}

export const setLoading = (value) => {
  setGlobalState('isLoadingState', value);
  // console.log( 'setLoading' );
  // console.log( value );
}

/**
 * @param event
 * @returns {Promise<void>}
 */
export const handleError = async (error) => {
  // console.log( 'handleError' );
  // console.log( error.errorMsg );
  switch (error.typeError) {
    case 'editable':
      await setAlertMsg({msg: error.errorMsg, min: 'agora', tit: 'Aviso'})
      break;
    case 'notFound':
      await setAlertMsg({msg: 'Ocorreu um erro!', min: 'agora', tit: 'Aviso'})
      break;
    default:
      // console.log( 'defaultCase' );
      await setAlertMsg({msg: 'Ocorreu um erro!', min: 'agora', tit: 'Aviso'})
      break;
  }

  setTimeout(() => {
    setAlertMsg({msg: null, min: null, tit: null})
  }, 30000);
}

/**
 *
 */
export const getUrlCallOmada = (type='Client') => {
  if ( sessionStorage.getItem(`login${type}`) !== null ) {
    const loginData = JSON.parse(sessionStorage.getItem(`login${type}`));
    const cookie = btoa(loginData.cookie.toString());
    const token = loginData.result.token.toString();

    return `?cookie=${cookie}&token=${token}&currentPage=1&currentPageSize=9999`;
  } else {
    return '';
  }
}

/**
 *
 * @returns {Promise<void>}
 */
export const getSitesUser = async ()  => {
  const url = getUrlCallOmada();
  const dados = await getAllAction(`user${url}` );

  if ( dados.error === false ) {
    sessionStorage.setItem('sitesUser', JSON.stringify(dados.data));
    setCookie('sitesUserCk', JSON.stringify(dados.data), 1);
    setGlobalState('siteSelectedState', dados.data.result.privilege.lastVisited);
    setCookie('siteSelectedCk', dados.data.result.privilege.lastVisited, 1);

    // console.log( 'sessionStorage - sitesUser' );
    // console.log( sessionStorage.getItem('sitesUser') );
    // console.log( 'cookie - sitesUserCk' );
    // console.log( getCookie('sitesUserCk') );
  }

  if ( dados.error === true ) {
    sessionStorage.setItem('loginClient');
    sessionStorage.setItem('loginAdmin');
    sessionStorage.setItem('sitesUser');
    setCookie('sitesUserCk', null, -1);
    setCookie('siteSelectedCk', null, -1);
    handleError(dados);
  }

  // setLoading(false);
  setGlobalState('isLoggedInState', true);
}

/**
 *
 * @returns {Promise<void>}
 */
export const getLoginAdmin = async () => {
  const dados  = await getAllAction('login?admin=true');

  if ( dados.error === false ) {
    sessionStorage.setItem('loginAdmin', JSON.stringify(dados.data));

    console.log( 'sessionStorage - loginAdmin' );
    console.log( sessionStorage.getItem('loginAdmin') );
  }

  if ( dados.error === true ) {
    sessionStorage.removeItem('loginClient');
    sessionStorage.removeItem('loginAdmin');
    sessionStorage.removeItem('sitesUser');
    deleteCookie('sitesUserCk');
    deleteCookie('siteSelectedCk');
    handleError(dados);
  }

  getSitesUser();
}

/**
 *
 * @returns {Promise<void>}
 */
export const getLoginClient = async () => {
  setAlertMsg({msg: null, min: null, tit: null});
  setLoading(true);
  setGlobalState('isLoggedInState', false);

  // console.log( 'OmadaServices - getLoginClient' );
  if ( sessionStorage.getItem('loginClient') === null ) {

    const dados  = await getAllAction('login');
    if ( dados.error === false ) {
      sessionStorage.setItem('loginClient', JSON.stringify(dados.data));

      console.log( 'sessionStorage - loginClient' );
      console.log( sessionStorage.getItem('loginClient') );

      getLoginAdmin();
    }

    if ( dados.error === true ) {
      // console.log( dados );
      sessionStorage.removeItem('loginClient');
      sessionStorage.removeItem('loginAdmin');
      sessionStorage.removeItem('sitesUser');
      deleteCookie('sitesUserCk');
      deleteCookie('siteSelectedCk');
      handleError(dados);
    }

  } else {
    // setLoading(false);
    setGlobalState('isLoggedInState', true);
  }
}
