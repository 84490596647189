import Client from './Api';
import {TOKEN_KEY} from "./Auth";

/*
 * GET ALL
 */
const getAllAction = async (path) => {

    let retorno = null;

    const method = (
        await new Client().get(path)
            .then((data) => {
                return {
                    data: {
                        'error': false,
                        'data': data.data
                    }}
            }).catch(function(error){
                return {
                    data: {
                        'error': true,
                        'data': error
                    }}
        })
    );

    if ( method.data.error === true )
        retorno = handleError(method.data.data);
    else
        retorno = method.data

    return retorno;

};

/*
 * GET ONE
 */
const getAction = async (path) => {

    let retorno = null;

    const method = (
        await new Client().get(path)
            .then((data) => {
                return {
                    data: {
                        'error': false,
                        'data': data.data
                    }}
            }).catch(function(error){
                return {
                    data: {
                        'error': true,
                        'data': error
                    }}
            })
    );

    if ( method.data.error === true )
        retorno = handleError(method.data.data);
    else
        retorno = method.data

    return retorno;

};

/*
 * INSERT
 */
const createAction = async (path,userData) => {

    let retorno = null;

    const method = (
        await new Client().post(path, userData)
            .then((data) => {
                return {
                    data: {
                        'error': false,
                        'data': data.data
                    }}
            }).catch(function(error){
                return {
                    data: {
                        'error': true,
                        'data': error
                    }}
            })
    );

    if (method.data.error === true)
        retorno = handleError(method.data.data);
    else
        retorno = method.data

    return retorno;
};

/*
 * UPDATE
 */
const updateAction = async (path,userData) => {

    let retorno = null;

    const method = (
        await new Client().put(path, userData)
            .then((data) => {
                return {
                    data: {
                        'error': false,
                        'data': data.data
                    }}
            }).catch(function(error){
                return {
                    data: {
                        'error': true,
                        'data': error
                    }}
            })
    );

    if (method.data.error === true)
        retorno = handleError(method.data.data);
    else
        retorno = method.data

    return retorno;
};

/*
 * PATCH
 */
const patchAction = async (path,userData) => {

      let retorno = null;

      const method = (
          await new Client().patch(path, userData)
              .then((data) => {
                  return {
                      data: {
                          'error': false,
                          'data': data.data
                      }}
              }).catch(function(error){
                  return {
                      data: {
                          'error': true,
                          'data': error
                      }}
              })
      );

      if (method.data.error === true)
          retorno = handleError(method.data.data);
      else
          retorno = method.data

      return retorno;
}

/*
 * DELETE
 */
const delAction = async (path,id) => {

    let retorno = null;

    const method = (
        await new Client().delete(path + id)
            .then((data) => {
                return {
                    data: {
                        'error': false,
                        'data': data.data
                    }}
            }).catch(function(error){
                return {
                    data: {
                        'error': true,
                        'data': error
                    }}
            })
    );

    if (method.data.error === true)
        retorno = handleError(method.data.data);
    else
        retorno = method.data

    return retorno;
};

/*
 * ERROR
 */
const handleError = (error) => {

    let typeError = null;
    let errorFormat = null;
    let errorData = error.data ? error.data.data : error;

    if ( errorData.response.statusText == 'Unauthorized' ) {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem('getHash');

        window.location = '/login';
    }

    switch (errorData.response.status) {
        case 422:
            typeError   = 'editable';
            errorFormat = {
                'error': true,
                'typeError': typeError,
                'errorMsg': errorData.response.data.validation_messages}
            break;
        case 200:
            typeError   = 'notFound';
            errorFormat = {
                'error': true,
                'typeError': typeError,
                'errorMsg': errorData.response.data.validation_messages}
            break;
        case 401:
            typeError   = '401';
            errorFormat = {
                'error': true,
                'typeError': typeError,
                'errorMsg': errorData.response.data.validation_messages,
                'errorDetail': error.data.response}
            break;
        default:
            typeError   = '500';
            errorFormat = {
                'error': true,
                'typeError': typeError,
                'errorMsg': null}
            break;
    }

    return errorFormat;
}

/*
 *
 */
const loginAction = async (path,userData) => {

    let retorno = null;

    const method = (
        await new Client().post(path, userData)
            .then((data) => {
                return {
                    data: {
                        'error': false,
                        'data': data.data
                    }}
            }).catch(function(error){
                return {
                    data: {
                        'error': true,
                        'data': error,
                        'response': error.response.data
                    }}
            })
    );

    let access_token = false;

    if (method.data.error === true) {
        // console.log( 'method.data.error' );
        // console.log( method );
        retorno = handleError(method);
    }
    else
    {
        access_token = {
            access_token: method.data.data.access_token,
            refresh_token: method.data.data.refresh_token,
            scope: method.data.data.scope
        }

        retorno = method.data
    }

    //
    if ( access_token != false ) {
        localStorage.setItem(TOKEN_KEY, JSON.stringify(access_token));
    }

    //
    // console.log( 'retorno' );
    // console.log( retorno );
    return retorno;
}

const logoutAction = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('getHash');
}

export { getAllAction, getAction, createAction, updateAction, patchAction, delAction, loginAction, logoutAction };