/*
 *
 */
export const TOKEN_KEY = "@userhashtokensn"

export const getHash = () => localStorage.getItem('getHash')

export const getToken = () =>
{
    const token = JSON.parse(localStorage.getItem(TOKEN_KEY));

    if ( token === undefined )
        return token

    return token.access_token;
}

export const isAuthenticated = () =>
{
    const token = JSON.parse( localStorage.getItem(TOKEN_KEY) );

    if ( token !== null ) {
        // console.log( 'isAuthenticated:: true' );
        return true;
    }

    // console.log( 'isAuthenticated:: false' );
    return false;
}

/*
 *
 */
export const urlApi = () =>
{
    // console.log( process.env.PUBLIC_URL );
    /// console.log( process.env.REACT_APP_MY_VAR );
    // console.log( process.env.REACT_APP_VERSION );
    // console.log( process.env.REACT_APP_API_ENDPOINT );
    // console.log( process.env.REACT_APP_SOCKET );
    // console.log( window.location.hostname );

    //
    return process.env.REACT_APP_API_ENDPOINT;
}

/*
 *
 */
export const HeaderAuthorization = () =>
{
    if ( isAuthenticated() ) {
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        }
    }

    return {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${getHash()}`
        // 'Authorization': 'Basic YWRtaW46aGFzaGFkbWluMkAyQA=='
    }
}
