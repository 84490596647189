import {useEffect, useState} from "react";
import { styled } from '@mui/material/styles';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

function ComplexStatisticsCardDashboardGateways({gatewayMac, siteId, portStats}) {

  /**
   *
   * @param item
   * @returns {string}
   */
  const getColor = (item) => {
    let colorStatus = 'inherit';

    if ( item.status === 1 ) {
      colorStatus = 'success';

      if ( item.speed < 3 ) {
        colorStatus = 'warning';
      }

      if ( item.rxRate === 0 && item.txRate === 0 && item.internetState === 0 ) {
        colorStatus = 'error';
      }

      if ( item.rxRate === 0 && item.txRate === 0 && item.internetState === 1 ) {
        colorStatus = 'azulClaro';
      }

    } else if ( item.status === 0 ) {
      colorStatus = 'secondary';
    }

    return colorStatus;
  }

  /**
   *
   * @param item
   * @returns {string}
   */
  const getColorIcon = (item) => {
    const colorIcon = getColor(item);

    switch (colorIcon) {
      case 'success':
        return '#4caf4f';

      case 'warning':
        return '#ff9900';

      case 'error':
        return '#f44336';

      case 'secondary':
        return '#7b809a';

      case 'inherit':
        return '#1a2035';

      case 'azulClaro':
        return '#37a5b6';

      default:
        return '#1a2035';
    }
  }

  /**
   * @returns {JSX.Element}
   */
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const [openToolTip, setOpenToolTip] = useState({
    wan1: false,
    wan2: false,
    lan1: false,
    lan2: false,
    lan3: false,
  });

  const handleTooltipClose = () => {
    setOpenToolTip({
      wan1: false,
      wan2: false,
      lan1: false,
      lan2: false,
      lan3: false,
    });

  };

  const handleTooltipOpen = (event, port) => {
    event.preventDefault();

    switch (port) {
      case 'wan1':
        setOpenToolTip({wan1: true});
        break;

      case 'wan2':
        setOpenToolTip({wan2: true});
        break;

      case 'lan1':
        setOpenToolTip({lan1: true});
        break;

      case 'lan2':
        setOpenToolTip({lan2: true});
        break;

      case 'lan3':
        setOpenToolTip({lan3: true});
        break;

      default:
        setOpenToolTip({
          wan1: false,
          wan2: false,
          lan1: false,
          lan2: false,
          lan3: false,
        });
        break;
    }
  };

  const getValue = (item, tipo=null) => {
    if ( typeof item === 'undefined' ) {
      return null;
    }

    switch (tipo) {
      case 'gateway':
        return item.gateway;

      case 'priDns':
        return item.priDns;

      case 'sndDns':
        return item.sndDns;

        default:
          return null;
    }
  }

  return (
    <MDBox textAlign="center">
      <MDBox display="flex" justifyContent="center" alignItems="center" mt={2}>

        <MDBox ml={1}>
          <Icon fontSize="small" style={{color: `${getColor(portStats.usb)}`}}>
            language
          </Icon>
          <MDTypography variant="button" color={getColor(portStats.usb)} fontWeight="light" ml={0.5} mr={1} position="relative" top={-5}>
            USB
          </MDTypography>
        </MDBox>

        <CustomWidthTooltip key={portStats.wan.name} title={
          <>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">Nome: <b>{portStats.wan.portDesc}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">IP: <b>{portStats.wan.ip}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">Gateway: <b>{getValue(portStats.wan.wanPortIpv4Config,'gateway')}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">DNS1: <b>{getValue(portStats.wan.wanPortIpv4Config,'priDns')}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">DNS2: <b>{getValue(portStats.wan.wanPortIpv4Config,'sndDns')}</b></MDTypography>
          </>
        } placeholder="bottom"
                            style={{cursor: 'pointer'}}
                            onClose={handleTooltipClose}
                            open={openToolTip.wan1}
        >
          <MDBox ml={1} onClick={(e) => handleTooltipOpen(e, 'wan1')}>
            <Icon fontSize="small" style={{color: `${getColorIcon(portStats.wan)}`}}>
              language
            </Icon>
            <MDTypography variant="button" color={getColor(portStats.wan)} fontWeight="light" ml={0.5} mr={1} position="relative" top={-5}>
              WAN 1
            </MDTypography>
          </MDBox>
        </CustomWidthTooltip>

        <CustomWidthTooltip key={portStats.wan_lan_1.name} title={
          <>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">Nome: <b>{portStats.wan_lan_1.portDesc}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">IP: <b>{portStats.wan_lan_1.ip}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">Gateway: <b>{getValue(portStats.wan_lan_1.wanPortIpv4Config,'gateway')}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">DNS1: <b>{getValue(portStats.wan_lan_1.wanPortIpv4Config,'priDns')}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">DNS2: <b>{getValue(portStats.wan_lan_1.wanPortIpv4Config,'sndDns')}</b></MDTypography>
          </>
        } placeholder="bottom"
                            style={{cursor: 'pointer'}}
                            onClose={handleTooltipClose}
                            open={openToolTip.wan2}
        >
          <MDBox ml={1} onClick={(e) => handleTooltipOpen(e, 'wan2')}>
            <Icon fontSize="small" style={{color: `${getColorIcon(portStats.wan_lan_1)}`}}>
              language
            </Icon>
            <MDTypography variant="button" color={getColor(portStats.wan_lan_1)} fontWeight="light" ml={0.5} mr={1} position="relative" top={-5}>
              WAN 2
            </MDTypography>
          </MDBox>
        </CustomWidthTooltip>
      </MDBox>
      <MDBox display="flex" justifyContent="center" alignItems="center" mt={2}>

        <CustomWidthTooltip key={portStats.wan_lan_2.name} title={
          <>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit" style={{display:(portStats.wan_lan_2.mode===0?'block':'none')}}>Nome: <b>{portStats.wan_lan_2.portDesc}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">IP: <b>{portStats.wan_lan_2.ip}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">Gateway: <b>{portStats.wan_lan_2.ip}</b></MDTypography>
            {portStats.wan_lan_2.dhcpSettings &&
              <>
                <MDTypography fontWeight="light" fontSize="medium" color="inherit">DHCP Start: <b>{portStats.wan_lan_2.dhcpSettings[0].ipaddrStart}</b></MDTypography>
                <MDTypography fontWeight="light" fontSize="medium" color="inherit">DHCP End: <b>{portStats.wan_lan_2.dhcpSettings[0].ipaddrEnd}</b></MDTypography>
              </>
            }
          </>
        } placeholder="bottom"
                            style={{cursor: 'pointer'}}
                            onClose={handleTooltipClose}
                            open={openToolTip.lan1}
        >
          <MDBox ml={1} onClick={(e) => handleTooltipOpen(e, 'lan1')}>
            <Icon fontSize="small" style={{color: `${getColorIcon(portStats.wan_lan_2)}`}}>
              {portStats.wan_lan_2.mode===0?'language':'lan'}
            </Icon>
            <MDTypography variant="button" color={getColor(portStats.wan_lan_2)} fontWeight="light" ml={0.5} mr={1} position="relative" top={-5}>
              {portStats.wan_lan_2.mode===0?'WAN 3':'LAN 1'}
            </MDTypography>
          </MDBox>
        </CustomWidthTooltip>

        <CustomWidthTooltip key={portStats.lan_1.name} title={
          <>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">IP: <b>{portStats.lan_1.ip}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">Gateway: <b>{portStats.lan_1.ip}</b></MDTypography>
            {portStats.lan_1.dhcpSettings &&
              <>
                <MDTypography fontWeight="light" fontSize="medium" color="inherit">DHCP Start: <b>{portStats.lan_1.dhcpSettings[0].ipaddrStart}</b></MDTypography>
                <MDTypography fontWeight="light" fontSize="medium" color="inherit">DHCP End: <b>{portStats.lan_1.dhcpSettings[0].ipaddrEnd}</b></MDTypography>
              </>
            }
          </>
        } placeholder="bottom"
                            style={{cursor: 'pointer'}}
                            onClose={handleTooltipClose}
                            open={openToolTip.lan2}
        >
          <MDBox ml={1} onClick={(e) => handleTooltipOpen(e, 'lan2')}>
            <Icon fontSize="small" style={{color: `${getColorIcon(portStats.lan_1)}`}}>
              lan
            </Icon>
            <MDTypography variant="button" color={getColor(portStats.lan_1)} fontWeight="light" ml={0.5} mr={1} position="relative" top={-5}>
              LAN 2
            </MDTypography>
          </MDBox>
        </CustomWidthTooltip>

        <CustomWidthTooltip key={portStats.lan_2.name} title={
          <>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">IP: <b>{portStats.lan_2.ip}</b></MDTypography>
            <MDTypography fontWeight="light" fontSize="medium" color="inherit">Gateway: <b>{portStats.lan_2.ip}</b></MDTypography>
            {portStats.lan_2.dhcpSettings &&
              <>
                <MDTypography fontWeight="light" fontSize="medium" color="inherit">DHCP Start: <b>{portStats.lan_2.dhcpSettings[0].ipaddrStart}</b></MDTypography>
                <MDTypography fontWeight="light" fontSize="medium" color="inherit">DHCP End: <b>{portStats.lan_2.dhcpSettings[0].ipaddrEnd}</b></MDTypography>
              </>
            }
          </>
        } placeholder="bottom"
                            style={{cursor: 'pointer'}}
                            onClose={handleTooltipClose}
                            open={openToolTip.lan3}
        >
          <MDBox ml={1} onClick={(e) => handleTooltipOpen(e, 'lan3')}>
            <Icon fontSize="small" style={{color: `${getColorIcon(portStats.lan_2)}`}}>
              lan
            </Icon>
            <MDTypography variant="button" color={getColor(portStats.lan_2)} fontWeight="light" ml={0.5} mr={1} position="relative" top={-5}>
              LAN 3
            </MDTypography>
          </MDBox>
        </CustomWidthTooltip>

      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCardDashboardGateways.defaultProps = {
  portStats: {},
  color: "info",
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCardDashboardGateways.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  portStats: PropTypes.objectOf(PropTypes.object),
  gatewayMac: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired
};

export default ComplexStatisticsCardDashboardGateways;