import { createGlobalState } from 'react-hooks-global-state';
import {getCookie} from "./Functions";

const { setGlobalState, useGlobalState } = createGlobalState({
  defaultState: 'My global store',
  alertMsgState: {msg: null, min: null, tit: null},
  isLoadingState: true,
  isLoggedInState: false,
  siteSelectedState: (getCookie('siteSelectedCk') !== null) ? getCookie('siteSelectedCk') : null,
  gatewaySiteSelectedState: (getCookie('gatewaySiteSelectedCk') !== null) ? getCookie('gatewaySiteSelectedCk') : null,
  typeDeviceSelectedState: (getCookie('typeDeviceSelectedCk') !== null) ? getCookie('typeDeviceSelectedCk') : null,
  clientSelectedState: null,
  clientSelectedEditedState: null,
  pageIndexClientState: 0,
});

export { setGlobalState, useGlobalState };