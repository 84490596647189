/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState} from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";
import MDSnackbar from "../../../components/MDSnackbar";

//
import {getLoginClient, getSitesUser} from "../../../services/OmadaServices";
import { useGlobalState } from "../../../services/StoreServices";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  //
  const [alertMsg, setAlertMsg] = useState({msg: '', tit: '', min: ''});
  const [isLoading, setIsLoading] = useState(true);

  //
  const alertMsgState = useGlobalState('alertMsgState');
  const isLoadingState = useGlobalState('isLoadingState');
  const siteSelectedState = useGlobalState('siteSelectedState');
  const isLoggedInState = useGlobalState('isLoggedInState');

  //
  const [infoSB, setInfoSB] = useState(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);

  /**
   * DEFAULT LAYOUT
   */
  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  useEffect(() => {
    console.log("DashboardLayout Mounted");
    setIsLoading(true);
    // console.log( isLoading );
    getLoginClient();
  },[]);

  useEffect(() => {

    console.log( 'update isLoggedInState' );

    if ( siteSelectedState[0] === null && isLoggedInState[0] === true ) {
      getSitesUser();
    }

  }, [isLoggedInState[0]]);

  /**
   * LOADING
   */
  useEffect(() => {
    // console.log("UPDATE isLoadingState");
    // console.log("isLoadingState", isLoadingState[0]);

    setIsLoading(isLoadingState[0]);
  }, [isLoadingState[0]]);

  /**
   * ALERT MSG
   */
  useEffect(() => {
    // console.log("UPDATE alertMsgState");
    // console.log("alertMsgState", alertMsgState[0]);

    if ( alertMsgState &&alertMsgState[0].msg !== null )
    {
      setAlertMsg({msg: alertMsgState[0].msg, min: 'agora', tit: 'Aviso'})
      openInfoSB();

      setTimeout(() => {
        setAlertMsg({msg: '', min: '', tit: ''});
        closeInfoSB();
      }, 15000);
    }
  }, [alertMsgState[0]]);

  /**
   * ALERT BOX
   */
  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title={alertMsg.tit}
      content={alertMsg.msg}
      dateTime={alertMsg.min}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  /**
   * RENDER
   */
  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          // marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {renderInfoSB}

      {isLoading === true &&
        <MDBox color="text">
          Carregando...
        </MDBox>
      }

      {isLoading === false &&
        children
      }
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
