/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import projectsTableData from "layouts/clientes/data/projectsTableData";
import {setGlobalState, useGlobalState} from "../../services/StoreServices";
import {getUrlCallOmada, handleError} from "../../services/OmadaServices";
import {getAllAction} from "../../services/ApiServices";
import EditClient from "./edit";

function Clientes() {
  // const { columns: pColumns, rows: pRows } = projectsTableData({teste: "teste"});

  //
  const isLoggedInState = useGlobalState('isLoggedInState');
  const siteSelectedState = useGlobalState('siteSelectedState');
  const clientSelectedEditedState = useGlobalState('clientSelectedEditedState');

  //
  const [clientes, setClientes] = useState([]);
  const { columns: pColumns, rows: pRows, clients: pClients } = projectsTableData(clientes);

  useEffect(() => {
    console.log("Clientes Mounted");
  }, []);

  useEffect(() => {
    // console.log( 'mudou pRows' );
    if ( pClients.length > 0 ) {
      setClientes(pClients)
    }
  }, [pRows]);

  /**
   *
   * @returns {Promise<void>}
   */
  const getClients = async () => {
    // console.log( 'getClients' );
    setGlobalState('isLoadingState', true);

    let url = getUrlCallOmada();
    url = url !== '' ? `${url}&site_id=${siteSelectedState[0]}` : '';
    // console.log( url );
    const dados = await getAllAction(`clients${url}`);

    if ( dados.error === false ) {
      // console.log(dados.data);
      setClientes(dados.data.result.data);
      setGlobalState('pageIndexClientState', 0)
      setGlobalState('isLoadingState', false);
    }

    if ( dados.error === true ) {
      handleError(dados);
    }
  }

  useEffect(() => {
    if ( isLoggedInState[0] === true && siteSelectedState[0] !== null ) {
      getClients();

      /*
      setTimeout(() => {
        console.log( 'setTimeout - getClients' );
          getClients();
      },60000);
      */
    }
  }, [isLoggedInState[0]]);

  //
  useEffect(() => {
    if ( isLoggedInState[0] === true && siteSelectedState[0] !== null ) {
      getClients();
    }
  }, [siteSelectedState[0]]);

  //
  useEffect(() => {

    const updatedClientes = clientes.map((cliente) => {
      if (cliente.mac === clientSelectedEditedState[0].mac) {
        return {
          ...cliente,
          name: clientSelectedEditedState[0].name
        };
      }
      return cliente;
    });

    setClientes(updatedClientes);

  }, [clientSelectedEditedState[0]]);

  //
  useEffect(() => {
    setTimeout(() => {
      console.log( `refresh 2 minutes` );
      getClients();
    },120000);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>

        <EditClient />

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Clientes
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Clientes;
