/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

//
import logoOtopina from "assets/images/logo-otopina-branco.png";

//
import {FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import {setGlobalState, useGlobalState} from "../../../services/StoreServices";
import breakpoints from "../../../assets/theme/base/breakpoints";
import {getCookie} from "../../../services/Functions";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  //
  const [showUnidades, setShowUnidades] = useState(true);

  useEffect(() => {

    if (route[1] === "dashboard") {
      setShowUnidades(false);
    }

  }, [route]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  /**
   *
   * @param event
   */
  const [siteSelected, setSiteSelected] = useState('');
  const siteSelectedState = useGlobalState('siteSelectedState');
  const isLoggedInState = useGlobalState('isLoggedInState');
  const [sitesUser, setSitesUser] = useState([]);

  useEffect(() => {
    if (isLoggedInState[0] === true) {
      // const tempSitesUser = JSON.parse(sessionStorage.getItem('sitesUser'));
      const tempSitesUser = JSON.parse(getCookie('sitesUserCk'));
      // console.log( 'tempSitesUser' );
      // console.log( tempSitesUser.result.privilege.sites );
      setSitesUser(tempSitesUser.result.privilege.sites);
    }
  }, [isLoggedInState[0]]);

  useEffect(() => {
    // console.log( 'setSiteSelected(siteSelectedState[0]);' );
    setSiteSelected(siteSelectedState[0]);
  }, [siteSelectedState[0]]);

  const handleChange = (event) => {
    // console.log( 'handleChange' );
    // console.log( event.target.value );
    setGlobalState('siteSelectedState', event.target.value);
  }

  //
  const [propsLogoMobile, setPropsLogoMobile] = useState({
    position: 'absolute',
    left: '0',
  });

  const propsLogoNoMobile = {
    position: 'absolute'
  };

  const [propsBreadcrumbMobile, setPropsBreadcrumbMobile] = useState({
    marginLeft: '145px',
    position: 'relative',
  });

  const propsBreadcrumbNoMobile = {
    marginLeft: '75px',
    position: 'absolute',
  };

  const [propsSelectMobile, setPropsSelectMobile] = useState({
    marginLeft: 'auto',
    marginRight: '-50%',
    marginTop: '15px',
  });

  const propsSelectNoMobile = {
    marginLeft: 'auto',
  };

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        console.log( 'mobile' );
      } else {
        setPropsBreadcrumbMobile(propsBreadcrumbNoMobile);
        setPropsLogoMobile(propsLogoNoMobile);
        setPropsSelectMobile(propsSelectNoMobile);
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>

        <MDBox {...propsLogoMobile}>
          <MDBox
            position="relative"
            width="50px"
            height="50px"
            sx={{
              backgroundImage: `url(${logoOtopina})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </MDBox>

        <MDBox {...propsBreadcrumbMobile} color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })} {...propsSelectMobile}>
            { /* * }
            <MDBox pr={1} style={{display:'none'}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                <Select
                  style={{minWidth: '100px', width: '100%', height: '40px'}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={siteSelected}
                  label="Site"
                  onChange={handleChange}
                >
                  { sitesUser && sitesUser.map((site, index) => (
                    <MenuItem key={site.key} value={site.key}>{site.name}</MenuItem>
                  )) }
                </Select>
              </FormControl>
            </MDBox>
            { /* */ }
            { showUnidades === true &&
            <MDBox pr={1} display="flex">
              <MDTypography variant="h6" fontWeight="bold" color="text.primary" mr={1.5}>
                Unidade:
              </MDTypography>
              <MDTypography variant="h6" fontWeight="bold" color="text">
                { sitesUser && sitesUser.map((site, index) => (
                  site.key === siteSelected ? site.name : null
                )) }
              </MDTypography>
            </MDBox>
            }
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
