/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useEffect, useState} from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import MDBadge from "../../../components/MDBadge";
import {setOpenMYDocker, useMaterialUIController} from "../../../context";
import {setGlobalState, useGlobalState} from "../../../services/StoreServices";
import {getUrlCallOmada, handleError} from "../../../services/OmadaServices";
import {createAction, getAllAction, updateAction} from "../../../services/ApiServices";

export default function data(clients) {
  const [controller, dispatch] = useMaterialUIController();
  const dados = clients;

  const [newDadosClients, setNewDadosClients] = useState([]);
  const [desbloqueado, setDesbloqueado] = useState(false);

  const siteSelectedState = useGlobalState('siteSelectedState');
  const typeDeviceSelectedState = useGlobalState('typeDeviceSelectedState');

  useEffect(() => {
    // console.log("Clientes Table Mounted");
    setNewDadosClients(dados);
  }, []);

  const handleClient = (client) => {
    setGlobalState('clientSelectedState', client);
    setOpenMYDocker(dispatch, true)
  }

  const calcTraffic = (traffic) => {
    const trafficValue = traffic / 1024 / 1024;
    const trafficValueRounded = trafficValue.toFixed(2);
    return trafficValueRounded;
  }

  /**
   *
   * @param clienteData
   * @returns {Promise<void>}
   */
  const handleBlock = async (clienteData) => {
    // console.log( 'handleBlock' );

    const block = clienteData.block===false ? 'true' : 'false';

    let url = getUrlCallOmada('Admin');
    url = url !== '' ? `${url}&site_id=${siteSelectedState[0]}` : '';
    url = url !== '' ? `${url}&mac=${clienteData.mac}` : '';

    const dadosPost = await createAction(`insight${url}`, {block: block});

    if ( dadosPost.error === false ) {
      // console.log( 'handleBlock', dadosPost );

      let status = true;
      if ( clienteData.active === false ) {
        status = false;
      }

      let blockResult = false;
      if ( block === 'true' ) {
        blockResult = true;
        status = false;
        setDesbloqueado(false);
      } else {
        setDesbloqueado(true);

        setTimeout(() => {
          setDesbloqueado(false);
        },120015);
      }

      const updatedClientes = dados.map((cliente) => {
        if (cliente.mac === clienteData.mac) {
          return {
            ...cliente,
            block: blockResult,
            active: status,
          };
        }
        return cliente;
      });

      setNewDadosClients(updatedClientes);

    }

    if ( dadosPost.error === true ) {
      handleError(dadosPost);
    }

  }

  /**
   *
   * @param dados
   * @returns {string}
   */
  const checkBlockColor = (clientData) => {
    if ( clientData.block === true ) {
      return 'error';
    }

    if ( clientData.active === true ) {
      return 'success';
    }

    if ( desbloqueado === true && clientData.block === false && clientData.active === false ) {
      return 'warning';
    }

    return 'dark';
  }

  /**
   *
   * @param dados
   * @returns {string}
   */
  const checkBlock = (clientData) => {
    if ( clientData.block === true ) {
      return 'bloqueado';
    }

    if ( clientData.active === true ) {
        return 'conectado';
    }

    if ( desbloqueado === true && clientData.block === false && clientData.active === false ) {
      return 'aguardando';
    }

    return 'desconectado';
  }

  const getWifiBand = (radioId) => {
    if ( radioId === 3 ) {
      return '6 GHz';
    }

    if ( radioId === 2 ) {
      return '5 GHz';
    }

    return '2.4 GHz';
  }

  /**
   *
   * @param values
   * @returns {*[]}
   */
  const montaDados = (values) => {
    const rows = [];

    values.forEach( (dado) => {
      rows.push({
        client:(
          <MDTypography component="a" href="#" onClick={() => handleClient(dado)} color="text" fontWeight="light" fontSize="medium">
            <Icon style={{lineHeight: '30px', marginRight:'7px'}} fontSize="medium" >lan</Icon>
            <span>{dado.name!==''?dado.name:dado.mac}</span>
          </MDTypography>
        ),
        ip: (
          <MDTypography component="a" variant="button" color="text" fontWeight="medium">
            {dado.ip}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={checkBlock(dado)} color={checkBlockColor(dado)} variant="gradient" size="sm" />
          </MDBox>
        ),
        ap: (
          <MDTypography component="a" variant="button" color="text" fontWeight="medium">
            {dado.apName}
          </MDTypography>
        ),
        wifi: (
          <MDTypography component="a" variant="button" color="text" fontWeight="medium">
            {getWifiBand(dado.radioId)}
            &nbsp;&nbsp;
            {(dado.rssi < -80) &&
              <SignalCellular4BarIcon color="success" />
            }
            {(dado.rssi > -70 && dado.rssi < -51) &&
              <SignalCellular3BarIcon color="warning" />
            }
            {(dado.rssi > -50) &&
              <SignalCellular2BarIcon color="error" />
            }
            {(!dado.rssi || dado.rssi === 0) &&
              <SignalCellular0BarIcon color="secondary" />
            }
          </MDTypography>
        ),
        trafficDown: (
          <MDTypography component="a" variant="button" color="text" fontWeight="medium">
            {calcTraffic(dado.trafficDown)} MB
          </MDTypography>
        ),
        trafficUp: (
          <MDTypography component="a" variant="button" color="text" fontWeight="medium">
            {calcTraffic(dado.trafficUp)} MB
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" onClick={() => handleBlock(dado)} color={checkBlockColor(dado)}>
            <Icon>{dado.block===true?'no_encryption':'lock'}</Icon>
          </MDTypography>
        ),
      });
    });
    return rows;
  }

  useEffect(() => {
    // console.log( 'newDadosClients' );
    montaDados(newDadosClients)
  },[newDadosClients]);

  const columnsDefault = [
    { Header: "client", accessor: "client", width: "30%", align: "left" },
    { Header: "ip", accessor: "ip", align: "left" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "trafficDown", accessor: "trafficDown", align: "center" },
    { Header: "trafficUp", accessor: "trafficUp", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const columnsAp = [
      { Header: "client", accessor: "client", width: "30%", align: "left" },
      { Header: "ip", accessor: "ip", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "AP", accessor: "ap", align: "center" },
      { Header: "WiFi", accessor: "wifi", align: "center" },
      { Header: "trafficDown", accessor: "trafficDown", align: "center" },
      { Header: "trafficUp", accessor: "trafficUp", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ];

  return {
    columns: ((typeDeviceSelectedState[0] === 'ap')? columnsAp : columnsDefault),

    rows: montaDados(dados),

    clients: newDadosClients,
  };
}
