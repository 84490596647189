import axios from 'axios';

import { isAuthenticated, getToken, urlApi, HeaderAuthorization } from "./Auth"

/*
 *
 */
// add session token to all requests
const requestInterceptor = (config) => ({
    ...config,
    baseURL: urlApi(),
    headers: HeaderAuthorization(),
    mode: 'no-cors',
    withCredentials: false,
});

class Api {
    constructor() {
        const options = {};
        const instance = axios.create(options);

        instance.interceptors.request.use(requestInterceptor);

        return instance;
    }
}

export default Api;
/*
 *
 */
